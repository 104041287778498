<template>
	<el-dialog v-if="dialogShow" :title="op == 'add' ? '新增任务' : '修改任务'" :close-on-click-modal="false" :visible="dialogShow" @close="closeDialog">
		<el-form :model="form" ref="form" :rules="rules">
			<el-divider content-position="left">任务信息</el-divider>
			<el-form-item prop="name" label="任务名称" label-width="200px">
				<el-input style="width: 90%" v-model="form.name" autocomplete="off" clearable placeholder="请输入任务名称"></el-input>
			</el-form-item>
			<el-form-item prop="taskDays" label="预计完成天数" label-width="200px">
				<el-input style="width: 90%" v-model="form.taskDays" oninput="value=value.replace(/[^0-9]/g,'').replace(/^0{1,}/g,'')" autocomplete="off" clearable placeholder="请输入预计完成天数">
					<template slot="append">天</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="itemCount" label="图片数量" label-width="200px">
				<el-input
					style="width: 90%"
					oninput="value=value.replace(/[^0-9]/g,'').replace(/^0{1,}/g,'')"
					v-model="form.itemCount"
					autocomplete="off"
					type="number"
					clearable
					placeholder="请输入图片数量">
					<template slot="append">张</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="timesRange" label="拍摄时间" label-width="200px">
				<el-time-picker is-range v-model="form.timesRange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围"></el-time-picker>
			</el-form-item>
			<el-form-item prop="requirement" label="任务要求" label-width="200px"><vue-tinymce style="width: 90%" v-model="requirement" :setting="setting" /></el-form-item>
			<el-form-item prop="region" label="所在区域" label-width="200px">
				<el-cascader style="width: 90%" placeholder="请选择区域" size="large" v-model="form.region" :options="options" clearable @change="formRegionChange"></el-cascader>
			</el-form-item>
			<el-form-item prop="coordinateList" label="" label-width="200px">
				<div class="amapContainer" id="amapContainer" style="width: 90%">
					<template v-if="customizeRegion">
						<el-button type="primary" size="mini" @click="drawPolygon" class="polygon" v-if="polygon.length == 0">绘制区域</el-button>
						<el-button type="primary" size="mini" @click="PolyEditor" class="polygon" v-if="polygon.length != 0 && !polygonConfirmFlag">编辑区域</el-button>
						<el-button type="primary" size="mini" @click="polygonConfirm" class="polygon" v-if="polygonConfirmFlag">确定区域</el-button>
						<el-button type="danger" class="delPolygon" size="mini" v-if="polyEditorObj" @click="polyDel">移除区域</el-button>
					</template>
				</div>
				<el-row>
					<el-checkbox class="customizeRegionCheckBox" v-model="customizeRegion">
						自定义区域
						<el-tooltip placement="top">
							<template slot="content">
								<el-row>
									勾选后点击地图左上角的
									<span class="messageTitle">“ 绘制区域 ”</span>
									按钮可自定义绘制任务所在区域，绘制完成后右键即可结束绘制，待确认自定义区域无误后点击
									<span class="messageTitle">“ 确定区域 ”</span>
									按钮即可绘制完成
								</el-row>
							</template>
							<i class="el-icon-question"></i>
						</el-tooltip>
					</el-checkbox>
				</el-row>
			</el-form-item>
			<el-divider content-position="left">媒介信息</el-divider>
			<el-form-item prop="mediaType" label="媒介类型" label-width="200px">
				<el-select v-model="form.mediaType" filterable placeholder="请选择媒介类型">
					<el-option v-for="item in mediaTypeList" :key="item.flowId" :label="item.value" :value="item.flowId"></el-option>
				</el-select>
				<el-button type="primary" @click="selectedConfirm">确定</el-button>
				<el-button type="primary" @click="selectedAll">全选</el-button>
			</el-form-item>
			<el-form-item prop="mediaTypeList" label="" label-width="200px">
				<el-table max-height="250" size="mini" :data="selectedMediaTypeList" border style="width: 90%">
					<el-table-column label="媒介名称" align="center" prop="value"></el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-popconfirm title="确定删除吗？" @confirm="delMediaType(scope.row.flowId)">
								<el-button slot="reference" type="text" size="small">删除</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</el-form-item>
			<el-divider content-position="left">价格信息</el-divider>
			<el-form-item prop="itemPrice" label="图片单价" label-width="200px">
				<el-input style="width: 90%" v-model="form.itemPrice" @input="limitInput($event, 'itemPrice')" autocomplete="off" type="number" clearable placeholder="请输入图片单价">
					<template slot="append">元/张</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="timeoutCutMoney" label-width="200px">
				<template slot="label">
					超时扣款比例
					<el-tooltip placement="top" content="从领取任务的那一刻计时，超过预计天数每天扣所设置比例的金额，扣完为止"><i class="el-icon-question"></i></el-tooltip>
				</template>
				<el-select filterable style="width: 90%" v-model="form.timeoutCutMoney" placeholder="请选择超时扣款比例">
					<el-option label="10%" value="10"></el-option>
					<el-option label="20%" value="20"></el-option>
					<el-option label="30%" value="30"></el-option>
					<el-option label="40%" value="40"></el-option>
					<el-option label="50%" value="50"></el-option>
					<el-option label="60%" value="60"></el-option>
					<el-option label="70%" value="70"></el-option>
					<el-option label="80%" value="80"></el-option>
					<el-option label="90%" value="90"></el-option>
					<el-option label="100%" value="100"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="type" label-width="200px">
				<template slot="label">
					任务超期处理
					<el-tooltip
						placement="top"
						content="自动回收：当任务超期至扣款100%后，任务将自动退回到任务池，下一个人领取后可继续采集，根据采集的数据计算二次奖励；自动提交：当任务超期至扣款100%后，任务将自动提交审核，且当前采集人无法获得任务奖励。">
						<i class="el-icon-question"></i>
					</el-tooltip>
				</template>
				<el-radio-group v-model="form.timeoutHandle">
					<el-radio-button :label="0">自动回收</el-radio-button>
					<el-radio-button :label="1">自动提交</el-radio-button>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if="form.timeoutHandle == 0" prop="itemAgainMoney" label-width="200px">
				<template slot="label">
					二次采集奖励
					<el-tooltip
						placement="top"
						content="第一个用户领取任务，如果超时，并扣完所采集好的图片金额，任务会重新发布，当第二个用户领取任务时，会把第一个用户采集好图片的金额按比例拿出来给第二个用户作为奖励">
						<i class="el-icon-question"></i>
					</el-tooltip>
				</template>
				<el-select filterable style="width: 90%" v-model="form.itemAgainMoney" placeholder="请选择二次采集奖励比例">
					<el-option label="10%" value="10"></el-option>
					<el-option label="20%" value="20"></el-option>
					<el-option label="30%" value="30"></el-option>
					<el-option label="40%" value="40"></el-option>
					<el-option label="50%" value="50"></el-option>
					<el-option label="60%" value="60"></el-option>
					<el-option label="70%" value="70"></el-option>
					<el-option label="80%" value="80"></el-option>
					<el-option label="90%" value="90"></el-option>
					<el-option label="100%" value="100"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="finishTarget" label-width="200px">
				<template slot="label">
					提前完成比例
					<el-tooltip placement="top" content="从领取任务的那一刻计时超过预计天数，采集的图片达到比例可提前完成"><i class="el-icon-question"></i></el-tooltip>
				</template>
				<el-select filterable style="width: 90%" v-model="form.finishTarget" placeholder="请选择自动完成比例">
					<el-option label="50%" value="50"></el-option>
					<el-option label="60%" value="60"></el-option>
					<el-option label="70%" value="70"></el-option>
					<el-option label="80%" value="80"></el-option>
					<el-option label="90%" value="90"></el-option>
					<el-option label="100%" value="100"></el-option>
				</el-select>
			</el-form-item>
			<el-divider content-position="left">设置</el-divider>
			<el-form-item prop="photoType" label-width="200px">
				<template slot="label">
					采集终端
					<el-tooltip placement="top" content="小程序：采集员需要填写广告主、点位、媒介等相关信息；app：采集员只负责拍照，广告主等信息由区代审核时自行填写">
						<i class="el-icon-question"></i>
					</el-tooltip>
				</template>
				<el-radio-group v-model="form.photoType">
					<el-radio-button :label="1">小程序</el-radio-button>
					<el-radio-button :label="2">APP</el-radio-button>
				</el-radio-group>
			</el-form-item>
			<el-form-item prop="type" label="任务模式" label-width="200px">
				<el-radio-group v-model="form.type">
					<el-radio-button :label="1">抢单</el-radio-button>
					<el-radio-button :label="2">指定</el-radio-button>
				</el-radio-group>
				<el-button type="text" v-if="form.type == 2" class="marginLeft-8" @click="designatedUser">{{ receiveUserCode ? receiveUserCode : '添加指定人员' }}</el-button>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="closeDialog">取 消</el-button>
			<el-button :disabled="!canSubmit" type="primary" @click="submit">确 定</el-button>
		</div>
		<designated-user :receiveUserCode="receiveUserCode" :designatedUserVisible="designatedUserVisible" @designatedUserClose="designatedUserClose" />
	</el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { regionData, CodeToText, TextToCode } from 'element-china-area-data';
import moment from 'moment';
import { getMediaTypeList } from '@/api/statisticalReport';
import { taskAdd, taskUpdata, queryTaskInfo } from '@/api/task';
import { queryRegionList, getAcountList } from '@/api/user';
import AMapLoader from '@amap/amap-jsapi-loader';
import { delComItem, solution } from '@/utils';
import DesignatedUser from './DesignatedUser';
import { isPrice } from '@/utils/validate';

export default {
	props: ['visible', 'op', 'addType'],
	components: { DesignatedUser },
	data() {
		const checkItemPrice = (rule, value, callback) => {
			if ('' == value) callback(new Error('价格不能为空'));
			if (0.0 == value) callback(new Error('价格大于0且最多为两位小数'));
			if (!isPrice(value)) callback(new Error('价格大于0且最多为两位小数'));
			else callback();
		};
		return {
			customizeRegion: false,
			polyEditor: null,
			polygonConfirmFlag: false,
			polyEditorObj: null,
			polygon: [],
			canSubmit: true,
			pageLoading: null,
			designatedUserVisible: false,
			requirement: '',
			form: {
				finishTarget: '100',
				itemAgainMoney: '50',
				timeoutCutMoney: '10',
				itemCount: '',
				itemPrice: '',
				type: 1,
				mediaType: '',
				timesRange: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				name: '',
				taskDays: '',
				region: [], // 任务所在区域
				timeoutHandle: 0,
				photoType: 1,
			},
			setting: {
				menubar: false,
				toolbar: 'undo redo  | fontsizeselect forecolor backcolor | bold italic underline strikethrough | removeformat |',
				toolbar_drawer: 'sliding',
				quickbars_selection_toolbar: 'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
				plugins: '',
				language: 'zh_CN', //本地化设置
				height: 350,
			},
			mediaTypeList: [],
			selectedMediaTypeList: [],
			sendSmsText: '发送验证码',
			moment,
			options: regionData,
			dialogShow: false,
			receiveUserCode: null,
			rules: {
				taskDays: [
					{
						required: true,
						trigger: 'change',
						message: '请输入预计完成天数',
					},
				],
				name: [
					{
						required: true,
						trigger: 'change',
						message: '请输入任务名称',
					},
				],
				timesRange: [
					{
						required: true,
						trigger: 'change',
						message: '请选择任务拍摄时间段',
					},
				],
				itemPrice: [
					{
						required: true,
						trigger: 'change',
						validator: checkItemPrice,
					},
				],
				itemCount: [
					{
						required: true,
						trigger: 'change',
						message: '请输入图片数量',
					},
				],
				region: [
					{
						required: true,
						trigger: 'change',
						message: '请选择任务所在区域',
					},
				],
				timeoutCutMoney: [
					{
						required: true,
						trigger: 'change',
						message: '请选择超时扣款比例',
					},
				],
				finishTarget: [
					{
						required: true,
						trigger: 'change',
						message: '请选择自动完成比例',
					},
				],
				itemAgainMoney: [
					{
						required: true,
						trigger: 'change',
						message: '请选择二次采集奖励比例',
					},
				],
			},
			userCodeList: [],
		};
	},
	watch: {
		visible(data) {
			this.dialogShow = data;
			this.customizeRegion = false;
			const _this = this;
			if (data) {
				AMapLoader.load({
					key: 'a68cfa9e236bde4322550467cef9a13b', // 申请好的Web端开发者Key，首次调用 load 时必填
					version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					plugins: ['AMap.DistrictSearch', 'AMap.PolygonEditor'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
					AMapUI: {
						// 是否加载 AMapUI，缺省不加载
						version: '1.1', // AMapUI 缺省 1.1
						plugins: [], // 需要加载的 AMapUI ui插件
					},
					// Loca: {
					//     // 是否加载 Loca， 缺省不加载
					//     version: "1.3.2", // Loca 版本，缺省 1.3.2
					// },
				})
					.then(AMap => {
						this.AMap = AMap;
						this.$nextTick(() => this.initMap(AMap));
					})
					.catch(e => {
						console.error(e);
					});
				if (this.userInfo.userType == 1) {
					// 查询区代账号负责的所有区域
					_this.pageLoading = _this.$loading({
						lock: true,
						text: '加载中,请稍后...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)',
						customClass: 'fullScreenLoading',
					});
					_this.getRegion();
				}
			}
		},
		customizeRegion(data) {
			if (!data) {
				this.polygonConfirmFlag = false;
				this.polygon = [];
				if (this.polyEditorObj) {
					this.map.remove([this.polyEditorObj.target]);
					this.polyEditor.close();
					this.polyEditorObj = null;
				}
			}
		},
	},
	computed: {
		...mapGetters(['userInfo']),
	},
	methods: {
		/**
		 * @param {string} value - 输入的值
		 * @param {string} name - 匹配的对象属性 [mkPrice | slPrice]
		 */
		limitInput(value, name) {
			this.form[name] =
				('' + value) // 第一步：转成字符串
					.replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
					.replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
					.replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
					.match(/^\d*(\.?\d{0,2})/g)[0] || ''; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
		},
		polyDel() {
			this.polygonConfirmFlag = false;
			this.polygon = [];
			this.map.remove([this.polyEditorObj.target]);
			this.polyEditorObj = null;
			this.polyEditor.close();
		},
		PolyEditor() {
			this.polygonConfirmFlag = true;
			this.polyEditor.setTarget(this.polyEditorObj.obj);
			this.polyEditor.open();
		},
		polygonConfirm() {
			const _this = this;
			_this.polygon = _this.polyEditorObj.obj.getPath();
			_this.polyEditor.close();
			_this.polygonConfirmFlag = false;
			_this.polyEditorObj.target.on('dblclick', () => {
				_this.polygonConfirmFlag = true;
				_this.polyEditor.setTarget(_this.polyEditorObj.obj);
				_this.polyEditor.open();
			});
		},
		//绘制多边形
		drawPolygon() {
			if (this.form.region.length == 0) {
				this.$message({
					message: '请先选择所在区域！',
					type: 'warning',
				});
				return;
			}
			if (this.polyEditor) {
				this.polyEditor.close();
				this.polyEditor.setTarget();
				this.polyEditor.open();
				return;
			}
			let polyEditor = new AMap.PolygonEditor(this.map);
			polyEditor.setTarget();
			polyEditor.open();
			const _this = this;
			polyEditor.on('add', function (data) {
				_this.polyEditorObj = data;
				let polygonTarget = data.target;
				polyEditor.addAdsorbPolygons(polygonTarget);
				_this.polygon = data.obj.getPath();
				_this.polygonConfirmFlag = true;
			});
			this.polyEditor = polyEditor;
		},
		getUserList() {
			// 获取平台用户列表
			let params = {
				userType: 3,
			};
			this.userCodeList = [this.userInfo.userCode];
			const _this = this;
			getAcountList(params)
				.then(res => {
					let dataList = res.data.dataList;
					dataList.map(item => {
						_this.userCodeList.push(item.userCode);
					});
					_this.getMediaTypeList();
				})
				.catch(err => {
					_this.canSubmit = false;
					if (_this.pageLoading) {
						_this.pageLoading.close();
					}
				});
		},
		getRegion() {
			let params = {
				userCode: this.userInfo.userCode,
			};
			const _this = this;
			queryRegionList(params)
				.then(res => {
					let dataList = res.data.dataList;
					const keys = ['province', 'city', 'district'];
					// dataList 是一个扁平的数组，是需要转换成城市三级级联数组才能给级联器使用
					let list = solution(dataList, keys);
					list.map(pItem => {
						pItem.label = pItem.value;
						pItem.value = TextToCode[pItem.label].code;
						pItem.children.map(cItem => {
							cItem.label = cItem.value;
							cItem.value = TextToCode[pItem.label][cItem.label].code;
							cItem.children.map(dItem => {
								dItem.label = dItem.value;
								dItem.value = TextToCode[pItem.label][cItem.label][dItem.label].code;
							});
						});
					});
					_this.options = list;
					_this.getUserList();
				})
				.catch(err => {
					_this.canSubmit = false;
					if (_this.pageLoading) {
						_this.pageLoading.close();
					}
				});
		},
		designatedUserClose(data) {
			this.designatedUserVisible = false;
			if (data) {
				this.receiveUserCode = data;
			}
		},
		designatedUser() {
			this.designatedUserVisible = true;
		},
		selectedAll() {
			let mediaTypeList = this.mediaTypeList;
			let selectedMediaTypeList = this.selectedMediaTypeList;
			mediaTypeList.map(item => {
				selectedMediaTypeList.push(item);
			});
			this.mediaTypeList = [];
			this.form.mediaType = '';
		},
		selectedConfirm() {
			if (this.form.mediaType && this.form.mediaType != '') {
				let mediaTypeList = this.mediaTypeList;
				let selectedMediaTypeList = this.selectedMediaTypeList;
				mediaTypeList.map(item => {
					if (item.flowId == this.form.mediaType) {
						selectedMediaTypeList.push(item);
					}
				});
				this.mediaTypeList = mediaTypeList.filter(item => item.flowId != this.form.mediaType);
				this.form.mediaType = '';
			} else {
				this.$message({
					message: '请先选择媒介类型',
					type: 'warning',
				});
			}
		},
		delMediaType(flowId) {
			let mediaTypeList = this.mediaTypeList;
			let selectedMediaTypeList = this.selectedMediaTypeList;
			selectedMediaTypeList.map(item => {
				if (item.flowId == flowId) {
					mediaTypeList.push(item);
				}
			});
			this.selectedMediaTypeList = selectedMediaTypeList.filter(item => item.flowId != flowId);
		},
		getMediaTypeList() {
			let params = {
				type: 1,
				userCodeList: this.userCodeList,
			};
			const _this = this;
			getMediaTypeList(params)
				.then(res => {
					_this.mediaTypeList = res.data.dataList;
					if (_this.op != 'add') {
						_this.getTaskfo();
					} else {
						if (_this.pageLoading) {
							_this.pageLoading.close();
						}
					}
				})
				.catch(err => {
					console.log(err);
					_this.canSubmit = false;
					if (_this.pageLoading) {
						_this.pageLoading.close();
					}
				});
		},
		formRegionChange(e) {
			// this.map.setCity(e[2]);
			var _this = this;
			if (_this.polygons && _this.polygons.length != 0) {
				_this.map.remove(_this.polygons);
			}
			//加载行政区划插件
			if (!_this.district) {
				//实例化DistrictSearch
				var opts = {
					subdistrict: 0, //获取边界不需要返回下级行政区
					extensions: 'all', //返回行政区边界坐标组等具体信息
					level: 'city', //查询行政级别为 市
				};
				_this.district = new AMap.DistrictSearch(opts);
			}
			//行政区查询
			let code = e[2];
			_this.district.search(code, function (status, result) {
				_this.polygons = [];
				var bounds = result.districtList[0].boundaries;
				if (bounds) {
					for (var i = 0, l = bounds.length; i < l; i++) {
						//生成行政区划polygon
						var polygon = new AMap.Polygon({
							strokeWeight: 2,
							path: bounds[i],
							fillOpacity: 0.4,
							fillColor: '#80d8ff',
							strokeColor: '#0091ea',
						});
						_this.polygons.push(polygon);
					}
				}
				_this.map.add(_this.polygons);
				// _this.AMap.Polygon.bind(_this.polygons);
				_this.map.setFitView(_this.polygons); //视口自适应
			});
		},
		initMap(AMap) {
			this.map = new AMap.Map('amapContainer');
		},
		updateMap(coordinate) {
			let list = JSON.parse(coordinate);
			let polygonList = list.map(item => {
				return [item.longitude, item.latitude];
			});
			let polygon = new AMap.Polygon({
				path: polygonList,
			});
			if (!this.polyEditor) {
				this.polyEditor = new AMap.PolygonEditor(this.map);
			}
			this.map.add([polygon]);
			this.map.setFitView();
			this.customizeRegion = true;
			this.polygon = polygon.getPath();
			this.polyEditorObj = {
				obj: polygon,
				target: polygon,
			};
		},
		getTaskfo() {
			const _this = this;
			let params = {
				taskId: this.op,
			};
			queryTaskInfo(params)
				.then(res => {
					_this.pageLoading.close();
					this.$refs.form.resetFields();
					const data = res.data;
					_this.form = {
						finishTarget: data.finishTarget,
						itemAgainMoney: data.itemAgainMoney,
						timeoutCutMoney: data.timeoutCutMoney,
						itemCount: data.itemCount,
						itemPrice: data.itemPrice / 1000,
						type: data.type,
						photoType: data.photoType,
						mediaType: '',
						timesRange: [moment(data.beginTime, 'HH:mm:ss'), moment(data.endTime, 'HH:mm:ss')],
						name: data.name,
						taskDays: data.taskDays,
						region: [TextToCode[data.province].code, TextToCode[data.province][data.city].code, TextToCode[data.province][data.city][data.district].code], // 任务所在区域
					};
					if (data.receiveUserCode) {
						_this.receiveUserCode = data.receiveUserCode;
					}
					_this.requirement = data.requirement;
					let mediaTypeArr = data.mediaType;
					let mediaTypeList = this.mediaTypeList;
					let selectedMediaTypeList = this.selectedMediaTypeList;
					mediaTypeList.map(item => {
						mediaTypeArr.map(mediaItem => {
							if (item.value == mediaItem) {
								selectedMediaTypeList.push(item);
							}
						});
					});
					// 重组媒介列表
					_this.mediaTypeList = delComItem(mediaTypeList, selectedMediaTypeList);
					_this.formRegionChange(_this.form.region);
					// 如有区域点位列表，则渲染在地图上
					if (data.coordinate) {
						_this.updateMap(data.coordinate);
					}
				})
				.catch(() => {
					_this.canSubmit = false;
					_this.pageLoading.close();
				});
		},
		submit() {
			const _this = this;
			let polygon = this.polygon.map(item => {
				return { longitude: item.lng, latitude: item.lat };
			});
			this.$refs.form.validate(valid => {
				if (valid) {
					if (!_this.requirement || _this.requirement == '') {
						this.$message({
							message: '请输入任务要求！',
							type: 'warning',
						});
						return;
					}
					if (!_this.selectedMediaTypeList || _this.selectedMediaTypeList == '' || _this.selectedMediaTypeList.length == 0) {
						this.$message({
							message: '请至少选择一种媒介！',
							type: 'warning',
						});
						return;
					}
					let selectedMediaTypeList = [..._this.selectedMediaTypeList];
					let mediaTypeIdList = [];
					selectedMediaTypeList.map(item => {
						mediaTypeIdList.push(item.flowId);
					});
					let params = {
						adCode: _this.form.region[2],
						beginTime: moment(_this.form.timesRange[0]).format('HH:mm:ss'),
						city: CodeToText[_this.form.region[1]],
						coordinateList: polygon,
						createUserCode: _this.userInfo.userCode,
						district: CodeToText[_this.form.region[2]],
						endTime: moment(_this.form.timesRange[1]).format('HH:mm:ss'),
						finishTarget: Number(_this.form.finishTarget),
						itemAgainMoney: Number(_this.form.itemAgainMoney),
						itemCount: Number(_this.form.itemCount),
						itemPrice: Number(_this.form.itemPrice) * 1000,
						mediaTypeIdList: mediaTypeIdList,
						name: _this.form.name,
						province: CodeToText[_this.form.region[0]],
						requirement: _this.requirement,
						taskDays: Number(_this.form.taskDays),
						timeoutCutMoney: Number(_this.form.timeoutCutMoney),
						type: _this.form.type,
						timeoutHandle: _this.form.timeoutHandle,
						photoType: _this.form.photoType,
					};
					if (_this.form.type == 2) {
						if (!_this.receiveUserCode || _this.receiveUserCode == '') {
							this.$message({
								message: '请选择指定采集人员',
								type: 'warning',
							});
							return;
						} else {
							params.receiveUserCode = _this.receiveUserCode;
						}
					}
					if (_this.op == 'add') {
						// 新增
						const loading = _this.$loading({
							lock: true,
							text: '提交中,请稍后...',
							spinner: 'el-icon-loading',
							background: 'rgba(0, 0, 0, 0.7)',
							customClass: 'fullScreenLoading',
						});
						taskAdd(params)
							.then(() => {
								loading.close();
								_this.$message({
									message: '操作成功',
									type: 'success',
								});
								_this.closeDialog(true);
							})
							.catch(err => {
								loading.close();
							});
					} else {
						// 更新
						params.taskId = _this.op;
						params.totalItemCount = Number(_this.form.itemCount);
						const loading = _this.$loading({
							lock: true,
							text: '提交中,请稍后...',
							spinner: 'el-icon-loading',
							background: 'rgba(0, 0, 0, 0.7)',
							customClass: 'fullScreenLoading',
						});
						taskUpdata(params)
							.then(() => {
								loading.close();
								_this.$message({
									message: '操作成功',
									type: 'success',
								});
								_this.closeDialog(true);
							})
							.catch(() => {
								loading.close();
							});
					}
				}
			});
		},
		closeDialog(flag) {
			this.$emit('onDialogClose', flag);
			this.$refs.form.resetFields();
			this.mediaTypeList = [];
			this.selectedMediaTypeList = [];
			this.requirement = '';
			this.receiveUserCode = null;
			this.polygon = [];
			this.customizeRegion = false;
			this.polygonConfirmFlag = false;
			this.polyEditor = null;
			this.polyEditorObj = null;
			this.polygons = [];
			this.map.destroy();
			// this.map = null;
		},
	},
};
</script>

<style lang="less" scoped>
/deep/ .customizeRegionCheckBox {
	.el-checkbox__label {
		font-size: 16px;
	}
}
.messageTitle {
	line-height: 20px;
	color: #fa8c16;
	.btnTitle {
		color: #1890ff;
	}
}
.amapContainer {
	height: 400px;
}
.polygon {
	left: 0;
	top: 0;
	margin-left: 0;
	position: absolute;
	z-index: 99;
}
.delPolygon {
	top: 0px;
	margin-left: 0;
	position: absolute;
	left: 100px;
	z-index: 99;
}
</style>
