<template>
	<div class="app-container">
		<el-row id="queryForm">
			<el-form :inline="true" ref="queryForm" :model="queryForm" @submit.native.prevent>
				<el-form-item prop="querySelectedValue">
					<el-input placeholder="请输入内容" v-model="queryForm.querySelectedValue" class="input-with-select" clearable>
						<el-select v-model="queryForm.querySelectedType" slot="prepend" placeholder="请选择">
							<el-option label="任务名称" value="name"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item prop="photoType">
					<el-select clearable v-model="queryForm.photoType" placeholder="选择采集终端">
						<el-option label="小程序" :value="1"></el-option>
						<el-option label="APP" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="dataRange">
					<el-date-picker v-model="queryForm.dataRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable></el-date-picker>
				</el-form-item>
				<el-form-item class="floatRight">
					<el-dropdown split-button type="primary" @click="queryData" @command="handleCommand">
						查询
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="reset">重置查询</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</el-form-item>
			</el-form>
		</el-row>
		<el-row v-if="userInfo && userInfo.userType == 1" class="marginBottom-8" id="addBtnRow">
			<el-button type="primary" @click="addTask">新增任务</el-button>
		</el-row>
		<el-tabs v-model="taskState" @tab-click="handleClick" id="tabsBox">
			<el-tab-pane label="已发布任务" name="1"></el-tab-pane>
			<el-tab-pane label="采集中任务" name="2"></el-tab-pane>
			<el-tab-pane label="审核中任务" name="3"></el-tab-pane>
			<el-tab-pane label="已上报任务" name="4"></el-tab-pane>
		</el-tabs>
		<el-table :max-height="tableHeight" element-loading-text="加载中" :data="list" border fit highlight-current-row>
			<el-table-column label="任务名称" :show-overflow-tooltip="true" align="center">
				<template slot-scope="scope">
					{{ scope.row.name }}
					<!-- <el-tag size="mini" v-if="scope.row.taskProcedureState != 0 && scope.row.isSubmit == 0">{{ scope.row.taskProcedureState == 1 ? "二次采集" : "超时提交" }}</el-tag>
                    <el-tag size="mini" v-if="scope.row.isSubmit == 1">强制提交</el-tag> -->
				</template>
			</el-table-column>
			<el-table-column label="创建人" align="center">
				<template slot-scope="scope">
					<el-button type="text" @click="queryUserInfo(scope.row.createUserCode)">{{ scope.row.createUserCode }}</el-button>
				</template>
			</el-table-column>
			<el-table-column v-if="taskState == 2 || taskState == 3 || taskState == 4" :key="Math.random()" label="领取人" align="center">
				<template slot-scope="scope">
					<el-button type="text" @click="queryUserInfo(scope.row.receiveUserCode)">{{ scope.row.receiveUserCode }}</el-button>
				</template>
			</el-table-column>
			<el-table-column :show-overflow-tooltip="true" label="发布区域" align="center">
				<template slot-scope="scope">
					{{ scope.row.province + scope.row.city + scope.row.district }}
				</template>
			</el-table-column>
			<el-table-column label="任务总金额（元）" align="center">
				<template slot-scope="scope">{{ (scope.row.itemCount * scope.row.itemPrice) / 1000 }}</template>
			</el-table-column>
			<!-- <el-table-column width="150" label="拍摄时间" align="center">
                <template slot-scope="scope">
                    {{ scope.row.beginTime + "~" + scope.row.endTime }}
                </template>
            </el-table-column> -->
			<el-table-column label="超时扣款比例" align="center">
				<template slot-scope="scope">
					{{ scope.row.timeoutCutMoney + '%' }}
				</template>
			</el-table-column>
			<el-table-column label="二次采集奖励" align="center">
				<template slot-scope="scope">
					{{ scope.row.itemAgainMoney + '%' }}
				</template>
			</el-table-column>
			<el-table-column label="自动完成比例" align="center">
				<template slot-scope="scope">
					{{ scope.row.finishTarget + '%' }}
				</template>
			</el-table-column>
			<el-table-column label="二次采集" align="center">
				<template slot-scope="scope">
					{{ scope.row.taskProcedureState == 0 && scope.row.isSubmit == 0 ? '是' : '否' }}
				</template>
			</el-table-column>
			<el-table-column label="采集终端" align="center">
				<template slot-scope="scope">
					<el-tag size="mini" label="小程序" v-if="!scope.row.photoType || scope.row.photoType == 1" type="success" effect="dark">小程序</el-tag>
					<el-tag size="mini" label="APP" v-if="scope.row.photoType == 2" effect="dark">APP</el-tag>
				</template>
			</el-table-column>
			<el-table-column width="170" label="发布时间" align="center">
				<template slot-scope="scope">
					{{ moment(scope.row.addTime).format('YYYY-MM-DD HH:mm:ss') }}
				</template>
			</el-table-column>
			<el-table-column width="170" v-if="taskState == 2 || taskState == 3 || taskState == 4" :key="Math.random()" label="领取时间" align="center">
				<template slot-scope="scope">
					{{ moment(scope.row.receiveTime).format('YYYY-MM-DD HH:mm:ss') }}
				</template>
			</el-table-column>
			<el-table-column v-if="taskState == 3" :key="Math.random()" label="提交状态" align="center">
				<template slot-scope="scope">
					<el-tag size="mini">
						{{ scope.row.isSubmit == 1 ? '强制提交' : scope.row.taskProcedureState == 0 || scope.row.taskProcedureState == 2 ? '正常提审' : '超时提审' }}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column fixed="right" align="center" label="操作">
				<template slot-scope="scope">
					<template v-if="taskState == 1">
						<template v-if="userInfo.userType == 1">
							<template v-if="scope.row.state == 1">
								<el-button class="marginLeft-8 marginRight-8" type="text" size="small" @click="edit(scope.row.taskId)">修改</el-button>
								<el-popconfirm title="确定删除吗？" @confirm="del(scope.row.taskId)">
									<el-button slot="reference" class="marginLeft-8 marginRight-8" type="text" size="small">删除</el-button>
								</el-popconfirm>
							</template>
							<template v-else>
								<el-button class="marginLeft-8 marginRight-8" type="text" size="small" @click="taskInfo(scope.row.taskId)">查看</el-button>
							</template>
						</template>
						<template v-else>
							<el-button class="marginLeft-8 marginRight-8" type="text" size="small" @click="taskInfo(scope.row.taskId)">查看</el-button>
						</template>
					</template>
					<el-button v-if="taskState == 2" class="marginLeft-8 marginRight-8" type="text" size="small" @click="taskInfo(scope.row.taskId)">查看</el-button>
					<el-button v-if="taskState == 2 && userInfo.userType == 1" class="marginLeft-8 marginRight-8" type="text" size="small" @click="submitReview(scope.row.taskId)">强制提交</el-button>
					<el-button
						v-if="taskState == 3 && userInfo.userType == 1"
						class="marginLeft-8 marginRight-8"
						type="text"
						size="small"
						@click="taskReview(scope.row.taskId, 'review', scope.row.photoType)">
						审核
					</el-button>
					<el-button
						v-if="taskState == 3 && userInfo.userType != 1"
						class="marginLeft-8 marginRight-8"
						type="text"
						size="small"
						@click="taskReview(scope.row.taskId, 'query', scope.row.photoType)">
						查看
					</el-button>
					<el-button v-if="taskState == 4" class="marginLeft-8 marginRight-8" type="text" size="small" @click="taskReview(scope.row.taskId, 'query', scope.row.photoType)">查看</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			class="marginTop-8 floatRight"
			layout="total, sizes, prev, pager, next"
			background
			:current-page="queryForm.pageNum + 1"
			:page-size="queryForm.pageSize"
			:total="queryForm.totalCount"
			@current-change="handleCurrentChange"
			@size-change="handleSizeChange" />
		<task-add :op="op" @onDialogClose="onDialogClose" :visible="diaLogShow" />
		<UserInfo :visible="drawerVisible" :userCode="userCode" @closeDrawer="closeDrawer" />
		<task-review :op="op" :opType="opType" @onTaskReviewClose="onTaskReviewClose" :taskReviewVisible="taskReviewVisible" />
		<app-task-review :op="op" :opType="opType" @onTaskReviewClose="onTaskReviewClose" :apptaskReviewVisible="apptaskReviewVisible" />
		<task-info :op="op" :taskInfoVisible="taskInfoVisible" @taskInfoClose="taskInfoClose"></task-info>
		<el-dialog append-to-body title="强制提交提示" :visible.sync="submitConfirmVisible" width="30%" :before-close="submitCancel">
			<div class="submitConfirmText">若执行强制提交操作，则该任务将被强制进入审核流程，并且不会进行价格结算。</div>
			<div class="submitConfirmText warningText">建议与该任务采集员协商后再进行此操作！</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="submitConfirmVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitConfirm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getTaskList, deleteTask, regionalAdd } from '@/api/task';
import { computeTableHeight } from '@/utils';
import state from '@/utils/state';
import moment from 'moment';
import TaskAdd from '../components/TaskAdd';
import UserInfo from '@/components/UserInfo';
import TaskReview from '../components/TaskReview';
import AppTaskReview from '../components/AppTaskReview';
import TaskInfo from '../components/TaskInfo';

export default {
	components: {
		TaskAdd,
		UserInfo,
		TaskReview,
		TaskInfo,
		AppTaskReview,
	},
	data() {
		return {
			taskInfoVisible: false,
			opType: 'review',
			taskReviewVisible: false,
			apptaskReviewVisible: false,
			drawerVisible: false,
			userCode: null,
			list: [],
			mediaTypeList: [],
			moment,
			diaLogShow: false,
			op: 'add',
			queryForm: {
				querySelectedType: 'name',
				querySelectedValue: '',
				state: '',
				dataRange: '',
				photoType: '',
				pageNum: 0,
				pageSize: 10,
				totalCount: 0,
			},
			taskState: '1',
			submitConfirmVisible: false,
			tableHeight: 0,
		};
	},
	computed: {
		state() {
			return state.state;
		},
		...mapGetters(['userInfo']),
	},
	created() {
		this.fetchData();
	},
	mounted() {
		const _this = this;
		this.$nextTick(() => {
			_this.tableHeight = computeTableHeight();
		});
		window.addEventListener('resize', () => {
			_this.tableHeight = computeTableHeight();
		});
	},
	methods: {
		submitCancel() {
			this.op = 'add';
			this.submitConfirmVisible = false;
		},
		submitConfirm() {
			const _this = this;
			let data = {
				taskId: _this.op,
			};
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			regionalAdd(data)
				.then(res => {
					loading.close();
					_this.$message({
						message: '操作成功',
						type: 'success',
					});
					_this.submitConfirmVisible = false;
					_this.fetchData();
				})
				.catch(err => {
					loading.close();
					console.log(err);
				});
		},
		submitReview(taskId) {
			this.op = taskId;
			this.submitConfirmVisible = true;
		},
		del(taskId) {
			let params = {
				taskId: taskId,
			};
			const _this = this;
			deleteTask(params)
				.then(() => {
					_this.$message({
						message: '删除成功',
						type: 'success',
					});
					_this.fetchData();
				})
				.catch(err => {
					console.log(err);
				});
		},
		taskInfoClose(flag) {
			this.op = 'add';
			this.taskInfoVisible = false;
			if (flag == true) {
				this.fetchData();
			}
		},
		taskInfo(taskId) {
			this.op = taskId;
			this.taskInfoVisible = true;
		},
		onTaskReviewClose(refeshFlag) {
			this.taskReviewVisible = false;
			this.apptaskReviewVisible = false;
			this.op = 'add';
			if (refeshFlag == true) {
				this.fetchData();
			}
		},
		taskReview(taskId, type, photoType) {
			this.opType = type;
			this.op = taskId;
			if (photoType == 1) {
				this.taskReviewVisible = true;
			} else if (photoType == 2) {
				this.apptaskReviewVisible = true;
			}
		},
		closeDrawer() {
			this.drawerVisible = false;
			this.userCode = null;
		},
		queryUserInfo(userCode) {
			this.userCode = userCode;
			this.drawerVisible = true;
		},
		handleClick(e) {
			this.list = [];
			this.queryForm.pageNum = 0;
			this.queryForm.pageSize = 10;
			this.queryForm.totalCount = 0;
			this.fetchData();
		},
		onDialogClose(refeshFlag) {
			this.diaLogShow = false;
			this.op = 'add';
			if (refeshFlag == true) {
				this.fetchData();
			}
		},
		handleSizeChange(val) {
			this.queryForm.pageSize = val;
			this.fetchData();
		},
		handleCurrentChange(val) {
			this.queryForm.pageNum = val - 1;
			this.fetchData();
		},
		edit(taskId) {
			this.op = taskId;
			this.diaLogShow = true;
		},
		addTask() {
			this.op = 'add';
			this.diaLogShow = true;
		},
		handleCommand(type) {
			if (type == 'reset') {
				this.$refs.queryForm.resetFields();
				this.fetchData();
			}
		},
		queryData() {
			this.fetchData();
		},
		fetchData() {
			const queryForm = this.queryForm;
			let params = {
				pageNum: queryForm.pageNum,
				pageSize: queryForm.pageSize,
				state: Number(this.taskState),
			};
			if (this.taskState != 1 && this.userInfo.userType == 2) {
				params.receiveUserCode = this.userInfo.userCode;
			}
			if (this.userInfo.userType == 2) {
				params.province = this.userInfo.province;
				params.city = this.userInfo.city;
			} else if (this.userInfo.userType == 1) {
				params.createUserCode = this.userInfo.userCode;
			}
			if (queryForm.querySelectedValue && queryForm.querySelectedValue != '') {
				params[queryForm.querySelectedType] = queryForm.querySelectedValue;
			}
			if (queryForm.mediaType && queryForm.mediaType != '') {
				params.mediaType = queryForm.mediaType;
			}
			if (queryForm.photoType && queryForm.photoType != '') {
				params.photoType = queryForm.photoType;
			}
			if (queryForm.dataRange && queryForm.dataRange != '') {
				params.maxAddTime = moment(queryForm.dataRange[1]).format('YYYY-MM-DD') + ' 23:59:59';
				params.minAddTime = moment(queryForm.dataRange[0]).format('YYYY-MM-DD') + ' 00:00:00';
			}
			const _this = this;
			const loading = _this.$loading({
				lock: true,
				text: '加载中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			getTaskList(params)
				.then(response => {
					_this.list = response.data.dataList;
					_this.queryForm.totalCount = response.data.totalCount;
					loading.close();
				})
				.catch(err => {
					loading.close();
				});
		},
	},
};
</script>
<style lang="less" scoped>
.submitConfirmText {
	font-size: 16px;
	line-height: 30px;
}
.warningText {
	color: red;
	margin-top: 16px;
	line-height: unset;
}
</style>
