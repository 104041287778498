<template>
    <el-dialog append-to-body :close-on-click-modal="false" title="视频预览" :visible="dialogVisible" width="60%" @close="handleClose">
        <div class="videoBox">
            <video autoplay controls class="video" :src="videoUrl" />
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ["dialogVisible", "videoUrl"],
    methods: {
        handleClose() {
            this.$emit("dialogClosed");
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
    padding: 0 0;
}
.videoBox {
    width: 100%;
    height: 520px;
    background-color: #000000;
    .video {
        width: 100%;
        height: 100%;
        display: inherit; //视频预览底部白边处理
    }
}
</style>
