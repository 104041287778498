<template>
	<el-drawer :close-on-press-escape="false" size="60%" title="审核任务" :wrapperClosable="false" :visible="apptaskReviewVisible" :before-close="closeDialog">
		<template v-if="taskInfo.isSubmit != 1 || (taskInfo.isSubmit == 0 && taskInfo.taskProcedureState != 1)">
			<el-row class="marginBottom-16 row">
				<el-col :span="6">任务模式：{{ taskInfo.type == 1 ? '抢单' : taskInfo.type == 2 ? '指定' : '--' }}</el-col>
				<el-col :span="6">
					领取人：
					<el-button style="padding: 0" size="mini" type="text" @click="queryUserInfo(taskInfo.receiveUserCode)">{{ taskInfo.receiveUserCode || '--' }}</el-button>
				</el-col>
				<el-col :span="6">领取任务日期：{{ moment(taskInfo.receiveTime).format('YYYY年MM月DD日') }}</el-col>
				<el-col :span="6">任务完成天数：{{ taskInfo.taskDays }} 天</el-col>
			</el-row>
			<el-row class="marginBottom-16 row">
				<el-col :span="6">任务总数量：{{ taskInfo.totalItemCount || 0 }}</el-col>
				<el-col :span="6">拍摄总数量：{{ taskInfo.totalCount || 0 }}</el-col>
				<el-col :span="6">已通过数量：{{ passCount }}</el-col>
				<el-col :span="6">已驳回数量：{{ failCount }}</el-col>
			</el-row>
			<el-row v-if="cutDayCount > 0" class="marginBottom-16 row">
				<el-col :span="6">通过率：{{ passingRate }} %</el-col>
				<el-col :span="6">完成采集日期：{{ moment(taskInfo.finishTime).format('YYYY年MM月DD日') }}</el-col>
				<el-col :span="6">逾期天数：{{ cutDayCount }} 天</el-col>
				<el-col :span="6">逾期扣款百分比：{{ taskInfo.timeoutCutMoney }} %</el-col>
			</el-row>
			<el-row class="marginBottom-16 row">
				<el-col :span="6">逾期扣款金额：{{ cutMoney }} 元</el-col>
				<el-col :span="6">结算金额：{{ settlementAmount }} 元</el-col>
			</el-row>
			<el-row class="marginBottom-16 row">
				<el-progress :text-inside="true" :color="customColors" :stroke-width="26" :percentage="passingRate"></el-progress>
			</el-row>
		</template>
		<template v-else>
			<el-alert :closable="false" type="warning" :title="taskInfo.isSubmit == 1 ? '强制提交审核任务' : '超时自动提交审核任务'" description="该任务审核完后不会进行价格结算，并且无法驳回。" />
		</template>
		<el-row class="marginBottom-16 row">
			<el-tabs v-model="tabsActive">
				<el-tab-pane v-if="opType == 'review'" label="未审核" name="1"></el-tab-pane>
				<el-tab-pane label="已审核" name="2"></el-tab-pane>
				<template v-if="opType == 'review' && tabsActive == 2 && unreadImgList && unreadImgList.length == 0">
					<el-row class="typeRadio marginBottom-8">
						<el-col :span="3">提交结果：</el-col>
						<el-col :span="18">
							<el-radio-group class="marginRight-16" v-model="type">
								<el-radio class="marginRight-8" border :label="1">同意</el-radio>
								<el-radio v-if="taskInfo.isSubmit != 1" border :label="2">驳回</el-radio>
							</el-radio-group>
							<el-input
								size="small"
								v-if="type == 2"
								style="width: 50%"
								v-model="days"
								oninput="value=value.replace(/[^0-9]/g,'').replace(/^0{1,}/g,'')"
								autocomplete="off"
								clearable
								placeholder="请输入宽限天数">
								<template slot="append">天</template>
							</el-input>
						</el-col>
						<el-col :span="3">
							<el-button type="primary floatRight" @click="submit">提交</el-button>
						</el-col>
					</el-row>
				</template>
				<div v-if="tabsActive == 1" class="imgReviewList">
					<el-row v-for="(item, index) in unreadImgList" :key="index" class="imgRow">
						<el-col :span="14" class="imgCol">
							<el-image lazy fit="scale-down" v-if="item.url.split('.')[1] != 'mp4'" :src="imgBaseUrl + item.url" @click="imgPreview(item.url)" />
							<video controls v-else :src="imgBaseUrl + item.url" class="video"></video>
							<el-tag size="mini" class="fileType" effect="dark">{{ item.url.split('.')[1] != 'mp4' ? '图片' : '视频' }}</el-tag>
							<div class="index">{{ index + 1 }}</div>
						</el-col>
						<el-col :span="10" class="imgReview">
							<el-form label-width="100px">
								<el-form-item label="审核">
									<el-radio-group v-model="item.reviewResults">
										<el-radio :label="1">通过</el-radio>
										<el-radio :label="2">拒绝</el-radio>
									</el-radio-group>
								</el-form-item>
								<template v-if="item.reviewResults == 1">
									<el-form-item label="点位名称" required>
										<el-autocomplete
											size="mini"
											style="width: 100%"
											v-model="item.pointName"
											value-key="name"
											:fetch-suggestions="queryPointNameSearchAsync"
											placeholder="请输入点位名称"
											@focus="pointNameInputFocus(item.flowId)"
											@select="handlePointNameSelect"
											@input="pointNameChange()"></el-autocomplete>
									</el-form-item>
									<el-form-item label="广告主名称" required>
<!--										<el-autocomplete-->
<!--											size="mini"-->
<!--											style="width: 100%"-->
<!--											v-model="item.advertisersName"-->
<!--											value-key="advertisersName"-->
<!--											:fetch-suggestions="querySearchAsync"-->
<!--											placeholder="请输入广告主名称"-->
<!--											@focus="advertisersNameInputFocus(item.flowId)"-->
<!--											@select="handleSelect"-->
<!--											@input="advertisersNameChange()"></el-autocomplete>-->
										<el-input v-model="item.advertisersName" size="mini" style="width: 100%" placeholder="请输入广告主名称"></el-input>
									</el-form-item>
									<el-form-item label="广告主行业" required>
										<el-select filterable v-model="item.industryName" size="mini" style="width: 100%" placeholder="请选择行业">
											<el-option v-for="item in industryNameList" :key="item.flowId" :label="item.value" :value="item.value"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="广告主电话">
										<el-input v-model="item.advertisersPhone" size="mini" placeholder="请输入广告主电话"></el-input>
									</el-form-item>
									<el-form-item label="媒介类型" required>
										<el-select filterable v-model="item.mediaName" size="mini" style="width: 100%" placeholder="请选择媒介类型">
											<el-option v-for="(item, index) in mediaNameTypeList" :key="index" :label="item" :value="item"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="媒介主名称">
										<el-input v-model="item.mediaOwnerName" size="mini" placeholder="请输入媒介主名称"></el-input>
									</el-form-item>
								</template>
								<template v-if="item.reviewResults == 2">
									<el-form-item label="拒绝理由">
										<el-select size="mini" v-model="item.reason" placeholder="请选择原因">
											<el-option v-for="(item, index) in reasonArr" :key="index" :label="item" :value="item"></el-option>
											<el-option label="自定义原因" value="自定义原因"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item v-if="item.reason == '自定义原因'" label="自定义原因">
										<el-input v-model="item.reasonNew" size="mini" placeholder="请输入自定义原因"></el-input>
									</el-form-item>
								</template>
							</el-form>
						</el-col>
					</el-row>
					<el-button class="submitBtn" v-if="unreadImgList.length != 0" type="primary" @click="reviewDataSubmit">提交</el-button>
				</div>
				<div v-if="tabsActive == 2" class="imgReviewList">
					<el-row v-for="(item, index) in reviewedImgList" :key="index" class="imgRow">
						<el-col :span="14" class="imgCol">
							<el-image lazy fit="scale-down" v-if="item.url.split('.')[1] != 'mp4'" :src="imgBaseUrl + item.url" @click="imgPreview(item.url)" />
							<video controls v-else :src="imgBaseUrl + item.url" class="video"></video>
							<el-tag size="mini" class="fileType" effect="dark">{{ item.url.split('.')[1] != 'mp4' ? '图片' : '视频' }}</el-tag>
							<el-tooltip placement="top" :content="item.reviewComments">
								<i v-show="item.reviewResults == 2" class="el-icon-warning warnIcon"></i>
							</el-tooltip>
							<div class="index">{{ index + 1 }}</div>
						</el-col>
						<el-col :span="10" class="imgReview">
							<el-form label-width="100px">
								<el-form-item label="图片审核">
									<span>{{ item.reviewResults == 1 ? '通过' : '拒绝' }}</span>
									<el-button v-if="opType == 'review' && item.collectReportState == 0" class="editBtn" @click="editData(item.flowId)" size="mini" type="primary">修改</el-button>
								</el-form-item>
								<template v-if="item.reviewResults == 1">
									<el-form-item label="点位名称">
										<span>{{ item.pointName }}</span>
									</el-form-item>
									<el-form-item label="广告主名称" required>
										<span>{{ item.advertisersName }}</span>
									</el-form-item>
									<el-form-item label="广告主行业" required>
										<span>{{ item.industryName }}</span>
									</el-form-item>
									<el-form-item label="广告主电话">
										<span>{{ item.advertisersPhone }}</span>
									</el-form-item>
									<el-form-item label="媒介类型">
										<span>{{ item.mediaName }}</span>
									</el-form-item>
									<el-form-item label="媒介主名称">
										<span>{{ item.mediaOwnerName }}</span>
									</el-form-item>
								</template>
								<template v-if="item.reviewResults == 2">
									<el-form-item label="拒绝理由">
										<span>{{ item.reviewComments }}</span>
									</el-form-item>
								</template>
							</el-form>
						</el-col>
					</el-row>
				</div>
			</el-tabs>
		</el-row>
		<Vviewer ref="viewer"></Vviewer>
		<user-info :visible="drawerVisible" :userCode="queryUserCode" @closeDrawer="closeDrawer" />
		<el-dialog append-to-body title="更新" :visible.sync="updataDialogVisible" width="30%">
			<el-form v-if="currenEditObj.flowId" label-width="100px">
				<el-form-item label="图片审核">
					<el-radio-group v-model="currenEditObj.reviewResults">
						<el-radio :label="1">通过</el-radio>
						<el-radio :label="2">拒绝</el-radio>
					</el-radio-group>
				</el-form-item>
				<template v-if="currenEditObj.reviewResults == 1">
					<el-form-item label="点位名称">
						<el-input v-model="currenEditObj.pointName" size="mini" placeholder="请输入点位名称"></el-input>
					</el-form-item>
					<el-form-item label="广告主名称" required>
<!--						<el-autocomplete-->
<!--							size="mini"-->
<!--							style="width: 100%"-->
<!--							v-model="currenEditObj.advertisersName"-->
<!--							value-key="advertisersName"-->
<!--							:fetch-suggestions="querySearchAsync"-->
<!--							placeholder="请输入广告主名称"-->
<!--							@focus="advertisersNameInputFocus(currenEditObj.flowId)"-->
<!--							@select="handleEditTaskSelect"></el-autocomplete>-->
						<el-input v-model="currenEditObj.advertisersName" size="mini" style="width: 100%" placeholder="请输入广告主名称"></el-input>
					</el-form-item>
					<el-form-item label="广告主行业" required>
						<el-select filterable v-model="currenEditObj.industryName" size="mini" style="width: 100%" placeholder="请选择行业">
							<el-option v-for="item in industryNameList" :key="item.flowId" :label="item.value" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="广告主电话">
						<el-input v-model="currenEditObj.advertisersPhone" size="mini" placeholder="请输入广告主电话"></el-input>
					</el-form-item>
					<el-form-item label="媒介类型" required>
						<el-select filterable v-model="currenEditObj.mediaName" size="mini" style="width: 100%" placeholder="请选择媒介类型">
							<el-option v-for="(item, index) in mediaNameTypeList" :key="index" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="媒介主名称">
						<el-input v-model="currenEditObj.mediaOwnerName" size="mini" placeholder="请输入媒介主名称"></el-input>
					</el-form-item>
				</template>
				<template v-if="currenEditObj.reviewResults == 2">
					<el-form-item label="拒绝理由">
						<el-select size="mini" v-model="currenEditObj.reason" placeholder="请选择原因">
							<el-option v-for="(item, index) in reasonArr" :key="index" :label="item" :value="item"></el-option>
							<el-option label="自定义原因" value="自定义原因"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="currenEditObj.reason == '自定义原因'" label="自定义原因">
						<el-input v-model="currenEditObj.reasonNew" size="mini" placeholder="请输入自定义原因"></el-input>
					</el-form-item>
				</template>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="updataDialogClose">取 消</el-button>
				<el-button type="primary" @click="updataDialogConfirm">确 定</el-button>
			</span>
		</el-dialog>
		<VideoPreview :dialogVisible="VideoPreviewShow" :videoUrl="videoUrl" @dialogClosed="VideoPreviewClose" />
		<RepeatDataReview :visible="repeatDataReviewVisible" :repeatData="repeatData" @repeatDataReviewClose="repeatDataReviewClose" />
	</el-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { getIndustryNameList, getAdvertiserList, queryTaskInfo, esaycollectionUpdate, examineTask, esaycollectionReviewData } from '@/api/task';
import { getDatapointList } from '@/api/statisticalReport';
import Vviewer from '@/components/Vviewer';
import VideoPreview from '@/components/VideoPreview';
import UserInfo from '@/components/UserInfo';
import RepeatDataReview from './RepeatDataReview.vue';
import { debounce } from '@/utils';
export default {
	props: ['apptaskReviewVisible', 'op', 'opType'],
	components: { Vviewer, UserInfo, VideoPreview, RepeatDataReview },
	data() {
		return {
			VideoPreviewShow: false,
			videoUrl: '',
			updataReviewComments: '',
			updataReviewResults: '2',
			currenEditObj: {},
			updataImgInfo: {},
			updataDialogVisible: false,
			pointDataList: [],
			refuseItem: false,
			drawerVisible: false,
			queryUserCode: null,
			tabsActive: '1',
			days: '',
			type: 1,
			reasonNew: '',
			reason: '',
			reasonArr: ['图片模糊', '图片光线太暗', '图片有遮挡物', '图片反光严重'],
			activeMediaId: '',
			activePointId: '',
			customColors: [
				{ color: '#f5222d', percentage: 20 },
				{ color: '#e6a23c', percentage: 40 },
				{ color: '#6f7ad3', percentage: 60 },
				{ color: '#1989fa', percentage: 80 },
				{ color: '#5cb87a', percentage: 100 },
			],
			failCount: 0,
			passCount: 0,
			settlementAmount: 0,
			passingRate: 0,
			taskInfo: {},
			moment,
			cutDayCount: 0, // 逾期天数
			cutMoney: 0, // 逾期扣除金额
			repeatDataReviewVisible: false,
			repeatData: null,
			reviewedImgList: [],
			unreadImgList: [],
			industryNameList: [],
			mediaNameTypeList: [],
			currentAdNameInputFocus: null,
			currentPointNameInputFocus: null,
		};
	},
	watch: {
		apptaskReviewVisible(data) {
			const _this = this;
			if (data) {
				if (this.opType == 'query') {
					this.tabsActive = '2';
				}
				_this.getTaskInfo();
			}
		},
	},
	computed: {
		...mapGetters(['userInfo']),
		imgBaseUrl() {
			return process.env.VUE_APP_RESOURCES_HOST;
		},
	},
	methods: {
		advertisersNameChange() {
			let unreadImgList = [...this.unreadImgList];
			unreadImgList.map(item => {
				if (item.flowId == this.currentAdNameInputFocus && item.advertisersName == '') {
					item.recordId = '';
				}
			});
			this.unreadImgList = unreadImgList;
		},
		pointNameChange() {
			let unreadImgList = [...this.unreadImgList];
			unreadImgList.map(item => {
				if (item.flowId == this.currentPointNameInputFocus && item.pointName == '') {
					item.pointId = '';
				}
			});
			this.unreadImgList = unreadImgList;
		},
		pointNameInputFocus(pointId) {
			this.currentPointNameInputFocus = pointId;
		},
		createPointNameFilter(queryString) {
			return item => {
				return item.name.toLowerCase().indexOf(queryString.toLowerCase()) == 0;
			};
		},
		queryPointNameSearchAsync(queryString, cb) {
			let params = {};
			getDatapointList(params)
				.then(res => {
					let results = queryString ? res.data.dataList.filter(this.createPointNameFilter(queryString)) : res.data.dataList;
					cb(results);
				})
				.catch(err => {
					console.log(err);
				});
		},
		editData(flowId) {
			this.updataDialogVisible = true;
			let reviewedImgList = JSON.parse(JSON.stringify(this.reviewedImgList));
			reviewedImgList.map(item => {
				if (item.flowId == flowId) {
					this.currenEditObj = item;
				}
			});
		},
		getIndustryList() {
			let params = {
				type: 3,
			};
			const _this = this;
			getIndustryNameList(params)
				.then(res => {
					_this.industryNameList = res.data.dataList;
				})
				.catch(err => {
					console.log(err);
				});
		},
		// querySearchAsync(queryString, cb) {
		// 	let params = {};
		// 	getAdvertiserList(params)
		// 		.then(res => {
		// 			let results = queryString ? res.data.dataList.filter(this.createAdvertiserFilter(queryString)) : res.data.dataList;
		// 			cb(results);
		// 		})
		// 		.catch(err => {
		// 			console.log(err);
		// 		});
		// },
		createAdvertiserFilter(queryString) {
			return item => {
				return item.advertisersName.toLowerCase().indexOf(queryString.toLowerCase()) == 0;
			};
		},
		advertisersNameInputFocus(flowId) {
			this.currentAdNameInputFocus = flowId;
		},
		handleEditTaskSelect(data) {
			if (this.currentAdNameInputFocus) {
				let currenEditObj = this.currenEditObj;
				currenEditObj.recordId = data.flowId;
				currenEditObj.advertisersPhone = data.advertisersPhone;
				currenEditObj.industryName = data.industryName;
				currenEditObj.mediaOwnerName = data.mediaOwnerName;
				this.currenEditObj = currenEditObj;
				this.currentAdNameInputFocus = null;
			}
		},
		handlePointNameSelect() {
			if (this.currentPointNameInputFocus) {
				let unreadImgList = [...this.unreadImgList];
				unreadImgList.map(item => {
					if (item.flowId == this.currentPointNameInputFocus) {
						item.pointId = data.pointId;
						item.pointName = data.name;
					}
				});
				this.unreadImgList = unreadImgList;
				this.currentPointNameInputFocus = null;
			}
		},
		handleSelect(data) {
			if (this.currentAdNameInputFocus) {
				let unreadImgList = [...this.unreadImgList];
				unreadImgList.map(item => {
					if (item.flowId == this.currentAdNameInputFocus) {
						item.recordId = data.flowId;
						item.advertisersPhone = data.advertisersPhone;
						item.industryName = data.industryName;
						item.mediaOwnerName = data.mediaOwnerName;
					}
				});
				this.unreadImgList = unreadImgList;
				this.currentAdNameInputFocus = null;
			}
		},
		reviewDataSubmit: debounce(function () {
			let unreadImgList = [...this.unreadImgList];
			let passIds = [];
			let rejectIds = [];
			const _this = this;
			unreadImgList.map(item => {
				if (item.reviewResults == 1) {
					let obj = {
						flowId: item.flowId,
						pointName: item.pointName,
						pointId: item.pointId || '',
						mediaName: item.mediaName,
						recordId: item.recordId,
						industryName: item.industryName,
						advertisersName: item.advertisersName,
						advertisersPhone: item.advertisersPhone,
						mediaOwnerName: item.mediaOwnerName,
					};
					passIds.push(obj);
				} else if (item.reviewResults == 2) {
					let obj = {
						flowId: item.flowId,
						reason: item.reason == '自定义原因' ? item.reasonNew : item.reason,
					};
					rejectIds.push(obj);
				}
			});
			let submitFlag = true;
			let submitType = '';
			let imgFlowId = '';
			passIds.map(item => {
				if (!item.pointName || item.pointName == '') {
					submitType = 'pointName';
					submitFlag = false;
					imgFlowId = item.flowId;
				}
				if (!item.advertisersName || item.advertisersName == '') {
					submitType = 'advertisersName';
					submitFlag = false;
					imgFlowId = item.flowId;
				}
				if (!item.industryName || item.industryName == '') {
					submitType = 'industryName';
					submitFlag = false;
					imgFlowId = item.flowId;
				}
				if (!item.mediaName || item.mediaName == '') {
					submitType = 'mediaName';
					submitFlag = false;
					imgFlowId = item.flowId;
				}
			});
			rejectIds.map(item => {
				if (!item.reason || item.reason == '') {
					submitType = 'reason';
					submitFlag = false;
					imgFlowId = item.flowId;
				}
			});
			if (!submitFlag) {
				let index = 0;
				unreadImgList.map((imgItem, imgIndex) => {
					if (imgItem.flowId == imgFlowId) {
						index = imgIndex;
					}
				});
				if (submitType == 'pointName') {
					this.$message({
						message: '第' + (index + 1) + '张图片缺少点位名称！',
						type: 'warning',
					});
				}
				if (submitType == 'advertisersName') {
					this.$message({
						message: '第' + (index + 1) + '张图片缺少广告主名称！',
						type: 'warning',
					});
				}
				if (submitType == 'industryName') {
					this.$message({
						message: '第' + (index + 1) + '张图片缺少广告主行业！',
						type: 'warning',
					});
				}
				if (submitType == 'reason') {
					this.$message({
						message: '第' + (index + 1) + '张图片缺少拒绝理由！',
						type: 'warning',
					});
				}
				if (submitType == 'mediaName') {
					this.$message({
						message: '第' + (index + 1) + '张图片缺少媒介类型！',
						type: 'warning',
					});
				}
				return;
			}
			if (!passIds.length && !rejectIds.length) {
				this.$message.warning('请审核图片！');
				return;
			}
			let params = {
				taskId: this.op,
				passIds,
				rejectIds,
				adCode: this.taskInfo.adCode,
			};
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			esaycollectionReviewData(params)
				.then(res => {
					loading.close();
					_this.getTaskInfo();
				})
				.catch(err => {
					loading.close();
					console.log(err);
				});
		}, 300),
		repeatDataInfo(data) {
			this.repeatDataReviewVisible = true;
			this.repeatData = data;
		},
		repeatDataReviewClose(type) {
			this.repeatDataReviewVisible = false;
			if (type == 1 || type == 2) {
				let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
				let activePointId = this.activePointId;
				let activeMediaId = this.activeMediaId;
				pointDataList.map(pointItem => {
					if (activePointId == pointItem.pointId) {
						pointItem.allIn = true;
						pointItem.voList.map((voItem, voIndex) => {
							if (voItem.mediaId == activeMediaId) {
								voItem.allIn = true;
								voItem.dataList.map(advertiserItem => {
									advertiserItem.vos.map(imgItem => {
										if (imgItem.flowId == this.repeatData.flowId && type == 2 && (!imgItem.reviewResults || imgItem.reviewResults == 3)) {
											imgItem.reviewResults = 2;
											imgItem.reason = '重复照片';
										} else if (imgItem.flowId == this.repeatData.flowId && type == 1 && (!imgItem.reviewResults || imgItem.reviewResults == 3)) {
											imgItem.reviewResults = 1;
											imgItem.reason = '通过';
										}
										if (!imgItem.reviewResults) {
											voItem.allIn = false;
										}
									});
								});
								voItem.checkedFlag = false;
							}
							if (!voItem.allIn) {
								pointItem.allIn = false;
							}
						});
					}
				});
				this.pointDataList = pointDataList;
				this.taskInfo.unPointVos = pointDataList;
			}
			this.repeatData = null;
		},
		VideoPreviewClose() {
			this.videoUrl = '';
			this.VideoPreviewShow = false;
		},
		updataDialogConfirm() {
			let currenEditObj = this.currenEditObj;
			let params = {
				flowId: currenEditObj.flowId,
				reviewResults: currenEditObj.reviewResults,
			};
			if (currenEditObj.reviewResults == 2) {
				if (currenEditObj.reason == '' || !currenEditObj.reason) {
					this.$message({
						message: '请输入拒绝原因！',
						type: 'warning',
					});
					return;
				}
				params.reviewComments = currenEditObj.reason == '自定义原因' ? currenEditObj.reasonNew : currenEditObj.reason;
			} else if (currenEditObj.reviewResults == 1) {
				params.pointName = currenEditObj.pointName;
				params.advertisersName = currenEditObj.advertisersName;
				params.industryName = currenEditObj.industryName;
				params.advertisersPhone = currenEditObj.advertisersPhone;
				params.mediaName = currenEditObj.mediaName;
				params.mediaOwnerName = currenEditObj.mediaOwnerName;
			}
			const _this = this;
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			esaycollectionUpdate(params)
				.then(res => {
					_this.$message({
						message: '更新成功',
						type: 'success',
					});
					_this.updataDialogClose();
					_this.getTaskInfo();
				})
				.catch(err => {
					loading.close();
					console.log(err);
				});
		},
		updataDialogClose() {
			this.updataImgInfo = {};
			this.updataDialogVisible = false;
			this.updataReviewResults = '2';
		},
		closeDrawer() {
			this.drawerVisible = false;
			this.queryUserCode = null;
		},
		queryUserInfo(userCode) {
			this.queryUserCode = userCode;
			this.drawerVisible = true;
		},
		submit: debounce(function () {
			let params = {
				taskId: this.op,
				type: this.type,
			};
			if (this.type == 2) {
				if (this.days == '' || !this.days) {
					this.$message({
						message: '请选择宽限天数',
						type: 'warning',
					});
					return;
				}
				params.days = this.days;
			}
			const _this = this;
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			examineTask(params)
				.then(() => {
					loading.close();
					_this.closeDialog(true);
				})
				.catch(err => {
					console.log(err);
					loading.close();
				});
		}, 300),
		imgPreview(url) {
			const _this = this;
			let type = url.split('.')[1];
			if (type == 'mp4') {
				this.videoUrl = this.imgBaseUrl + url;
				this.VideoPreviewShow = true;
			} else {
				this.$refs.viewer.show([
					{
						thumbnail: _this.imgBaseUrl + url,
						source: _this.imgBaseUrl + url,
					},
				]);
			}
		},
		getTaskInfo() {
			const _this = this;
			let params = {
				taskId: this.op,
			};
			const loading = _this.$loading({
				lock: true,
				text: '加载中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			queryTaskInfo(params)
				.then(res => {
					_this.taskInfo = res.data;
					_this.failCount = res.data.failCount;
					_this.passCount = res.data.passCount;
					_this.passingRate =
						Math.round(Math.floor((_this.passCount / res.data.totalItemCount) * 1000) / 10) >= 100 ? 100 : Math.round(Math.floor((_this.passCount / res.data.totalItemCount) * 1000) / 10);
					if (_this.passCount > res.data.totalItemCount) {
						_this.settlementAmount = (res.data.totalItemCount * res.data.itemPrice) / 1000;
					} else {
						_this.settlementAmount = (res.data.passCount * res.data.itemPrice) / 1000;
					}
					let finishTime = res.data.finishTime;
					// 如果实际完成时间大于任务所需完成的时间则逾期
					if (moment(finishTime).isAfter(moment(res.data.receiveTime).add(res.data.taskDays, 'days'))) {
						// 计算逾期天数
						let cutDayCount = moment(finishTime).diff(moment(res.data.receiveTime).add(res.data.taskDays, 'days'), 'days');
						let cutMoney = Math.round(Math.floor(_this.settlementAmount * (res.data.timeoutCutMoney / 100) * cutDayCount * 1000) / 10) / 100;
						_this.cutDayCount = cutDayCount;
						_this.cutMoney = cutMoney;
						_this.settlementAmount = _this.settlementAmount - cutMoney <= 0 ? 0 : Math.round(Math.floor((_this.settlementAmount - cutMoney) * 1000) / 10) / 100;
					}
					loading.close();
					let dataList = res.data.esayCollectionVos || [];
					dataList.map(item => {
						if (!item.pointName || item.pointName == '') {
							item.pointName = item.address;
						}
					});
					let unreadImgList = [];
					let reviewedImgList = [];
					dataList.map(item => {
						if (item.reviewResults == 3) {
							unreadImgList.push(item);
						} else {
							reviewedImgList.push(item);
						}
					});
					_this.unreadImgList = unreadImgList;
					_this.reviewedImgList = reviewedImgList;
					_this.mediaNameTypeList = res.data.mediaType;
					_this.getIndustryList();
					// let activePointId = "";
					// if (_this.tabsActive == 1) {
					//     if (res.data.unPointVos.length != 0) {
					//         activePointId = res.data.unPointVos[0].pointId;
					//     }
					// } else if (_this.tabsActive == 2) {
					//     if (res.data.pointVos.length != 0) {
					//         activePointId = res.data.pointVos[0].pointId;
					//     }
					// }
					// _this.activePointId = activePointId;
					// if (_this.activePointId && _this.activePointId != "") {
					//     _this.getVoList(activePointId);
					//     _this.queryListByFile();
					// } else {
					//     _this.pointDataList = [];
					// }
				})
				.catch(() => {
					loading.close();
				});
		},
		closeDialog(flag) {
			this.$emit('onTaskReviewClose', flag);
			this.days = '';
			this.type = 1;
			this.reasonNew = '';
			this.reason = '';
			this.activeMediaId = '';
			this.activePointId = '';
			this.tabsActive = '1';
			this.failCount = 0;
			this.passCount = 0;
			this.settlementAmount = 0;
			this.passingRate = 0;
			this.taskInfo = {};
			this.pointDataList = [];
			this.refuseItem = false;
			this.drawerVisible = false;
			this.queryUserCode = null;
			this.cutDayCount = 0;
			this.cutMoney = 0;
			this.reviewedImgList = [];
			this.unreadImgList = [];
			this.industryNameList = [];
			this.mediaNameTypeList = [];
		},
	},
};
</script>

<style lang="less" scoped>
.imgReviewList {
	position: relative;
	.editBtn {
		margin-left: 8px;
	}
	/deep/ .el-form-item {
		margin-bottom: 2px;
	}
	.submitBtn {
		position: fixed;
		right: 16px;
		bottom: 8px;
	}
	.imgRow {
		padding: 8px 0;
		border-bottom: 1px solid #cccccc;
		.imgCol {
			height: 300px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			.fileType {
				position: absolute;
				right: 4px;
				bottom: 4px;
			}
			.video {
				width: 100%;
				height: 100%;
				background-color: #000;
			}
			.warnIcon {
				position: absolute;
				right: 0;
				top: 0;
				color: #f5222d;
				font-size: 28px;
				cursor: pointer;
			}
			.index {
				width: 30px;
				height: 30px;
				background-color: rgb(0, 145, 255);
				border-radius: 50%;
				position: absolute;
				left: 8px;
				top: 8px;
				text-align: center;
				line-height: 30px;
				color: #ffffff;
			}
			.el-image {
				width: 100%;
				height: 100%;
				cursor: pointer;
				// img {
				// 	width: 100%;
				// 	height: 100%;
				// }
			}
		}
	}
	.imgRow:last-child {
		padding-bottom: 0;
		border-bottom: none;
	}
	.imgRow:first-child {
		padding-top: 0;
	}
}
/deep/ .reviewRemarkContent {
	width: 85%;
	.advertiserItemmRemark {
		width: 100%;
	}
	.advertiserItemmRemark2 {
		width: 86%;
	}
	.advertiserItemReasonSubmitBtn {
		width: 14%;
	}
}

/deep/ .pointImgLabel {
	width: 150px;
}
.pointImg {
	width: 100px;
	height: 80px;
	cursor: pointer;
}
.pointColItem {
	position: relative;
	.pointSubmitBtn {
		position: absolute;
		right: 28px;
		top: 8px;
	}
	.pointFlag {
		position: absolute;
		right: 28px;
		top: 12px;
	}
}
.titleSpan {
	font-size: 16px;
	font-weight: 700;
	display: flex;
	align-items: center;
	& > div {
		display: inline-block;
	}
	div.dir {
		width: 0;
		height: 20px;
		border: 2px solid #66b1ff;
		margin-right: 8px;
	}
}
/deep/ .el-collapse-item__content {
	padding-bottom: 0;
}
.typeRadio {
	display: flex;
	align-items: center;
}
.warnText {
	color: #ff4d4f;
}
.confirmBox {
	// display: flex;
	div {
		display: inline-block;
	}
}
/deep/ .phoneLabel {
	width: 10%;
}
/deep/ .phoneContent {
	width: 90%;
}
.imgRowBox {
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	.imgBox {
		width: 150px;
		height: 150px;
		margin-right: 8px;
		position: relative;
		display: inline-block;
		.imgItemReason,
		.imgItemReason-1,
		.imgItemReason-2 {
			font-size: 12px;
			width: 100%;
			.el-button {
				padding: 0;
			}
		}
		.imgItemReason-1 {
			color: #52c41a;
		}
		.imgItemReason-2 {
			color: #f5222d;
		}
		.warnIcon1 {
			color: #f2ce07;
			right: 32px;
		}
		.successIcon {
			position: absolute;
			right: 0;
			top: 0;
			color: #52c41a;
			font-size: 28px;
			cursor: pointer;
		}
		.detailImgTag {
			position: absolute;
			right: 4px;
			top: 4px;
			cursor: pointer;
		}
		.checkBox {
			position: absolute;
			left: 0;
			top: 0;
			border: 2px solid #ccc;
			padding: 4px;
			height: auto;
		}
		/deep/ .checkBox.is-checked {
			border-color: #409eff;
		}
		img {
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}
}

.row {
	padding: 0 32px;
	.el-col {
		font-size: 16px;
	}
}
</style>
