<template>
    <el-dialog width="60%" append-to-body title="指定用户" :close-on-click-modal="false" :visible="designatedUserVisible" @close="closeDialog">
        <el-row>
            <el-form :inline="true" ref="queryForm" :model="queryForm" @submit.native.prevent>
                <el-form-item prop="querySelectedValue">
                    <el-input placeholder="请输入内容" v-model="queryForm.querySelectedValue" class="input-with-select">
                        <el-select v-model="queryForm.querySelectedType" slot="prepend" placeholder="请选择">
                            <el-option label="姓名" value="userName"></el-option>
                            <el-option label="手机号" value="phone"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item class="floatRight">
                    <el-dropdown split-button type="primary" @click="queryData" @command="handleCommand">
                        查询
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="reset">重置查询</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>
            </el-form>
        </el-row>
        <el-table v-loading="listLoading" element-loading-text="加载中" :data="list" border fit highlight-current-row @current-change="handleCurrentRowChange">
            <el-table-column label="选择" align="center">
                <template slot-scope="scope">
                    <el-radio v-model="selectedUserCode" :label="scope.row.userCode"></el-radio>
                </template>
            </el-table-column>
            <el-table-column label="姓名" align="center">
                <template slot-scope="scope">
                    {{ scope.row.userName }}
                </template>
            </el-table-column>
            <el-table-column label="手机号" align="center">
                <template slot-scope="scope">
                    {{ scope.row.phone }}
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag effect="dark" :type="state.AcountStateColorType[scope.row.state]">{{ state.AcountState[scope.row.state] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="注册来源" align="center">
                <template slot-scope="scope">
                    {{ state.AcountSource[scope.row.source] }}
                </template>
            </el-table-column>
            <el-table-column label="所在区域" align="center">
                <template slot-scope="scope">
                    {{ scope.row.province + scope.row.city + scope.row.district }}
                </template>
            </el-table-column>
            <el-table-column label="注册时间" align="center">
                <template slot-scope="scope">
                    <span>{{ moment(scope.row.addTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="marginTop-8 pagination" layout="total, sizes, prev, pager, next" background :current-page="queryForm.pageNum + 1" :page-size="queryForm.pageSize" :total="queryForm.totalCount" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button :disabled="selectedUserCode == ''" type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { getCollecAcountList } from "@/api/user";
import state from "@/utils/state";
import moment from "moment";
export default {
    props: ["designatedUserVisible", "receiveUserCode"],
    data() {
        return {
            selectedUserCode: null,
            moment,
            listLoading: false,
            list: [],
            queryForm: {
                querySelectedType: "userName",
                querySelectedValue: "",
                pageNum: 0,
                pageSize: 10,
                totalCount: 0,
            },
        };
    },
    computed: {
        state() {
            return state.state;
        },
        ...mapGetters(["userInfo"]),
    },
    watch: {
        designatedUserVisible(data) {
            if (data) {
                this.selectedUserCode = this.receiveUserCode;
                this.fetchData();
            }
        },
    },
    methods: {
        submit() {
            if (this.selectedUserCode == "" || !this.selectedUserCode) {
                this.$message({
                    message: "请先选择用户",
                    type: "warning",
                });
                return;
            }
            this.closeDialog();
        },
        handleCurrentRowChange(val) {
            if (val) {
                this.selectedUserCode = val.userCode;
            }
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.fetchData();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val - 1;
            this.fetchData();
        },
        handleCommand(type) {
            if (type == "reset") {
                this.$refs.queryForm.resetFields();
                this.fetchData();
            }
        },
        queryData() {
            this.fetchData();
        },
        fetchData() {
            this.listLoading = true;
            const queryForm = this.queryForm;
            let params = {
                pageNum: queryForm.pageNum,
                pageSize: queryForm.pageSize,
                userType: 2,
            };
            if (queryForm.querySelectedValue && queryForm.querySelectedValue != "") {
                params[queryForm.querySelectedType] = queryForm.querySelectedValue;
            }
            const _this = this;
            getCollecAcountList(params)
                .then((response) => {
                    _this.list = response.data.dataList;
                    _this.queryForm.totalCount = response.data.totalCount;
                    _this.listLoading = false;
                })
                .catch((err) => {
                    _this.listLoading = false;
                });
        },
        closeDialog() {
            this.$emit("designatedUserClose", this.selectedUserCode);
            this.selectedUserCode = "";
            this.list = [];
            this.$refs.queryForm.resetFields();
            this.queryForm.pageNum = 0;
            this.queryForm.totalCount = 0;
        },
    },
};
</script>
<style lang="less" scoped>
.pagination {
    text-align: right;
}
</style>
