<template>
	<el-dialog append-to-body width="80%" title="任务详情" :visible="taskInfoVisible" :close-on-click-modal="false" :close-on-press-escape="false" @close="closeDialog">
		<template v-if="taskInfo.state == 1">
			<el-row>
				<div class="amapBox" id="amapBox" style="width: 100%"></div>
			</el-row>
			<el-descriptions direction="vertical" border :column="5">
				<el-descriptions-item label="任务名称">{{ taskInfo.name || '--' }}</el-descriptions-item>
				<el-descriptions-item label="创建人">
					<el-button type="text" @click="queryUserInfo(taskInfo.createUserCode)">{{ taskInfo.createUserCode || '--' }}</el-button>
				</el-descriptions-item>
				<el-descriptions-item label="领取人">
					<template v-if="taskInfo.receiveUserCode && taskInfo.receiveUserCode != ''">
						<el-button type="text" @click="queryUserInfo(taskInfo.receiveUserCode)">{{ taskInfo.receiveUserCode }}</el-button>
					</template>
					<template v-else>--</template>
				</el-descriptions-item>
				<el-descriptions-item label="预计完成天数">{{ taskInfo.taskDays || '--' }}</el-descriptions-item>
				<el-descriptions-item label="发布区域">{{ taskInfo.province + taskInfo.city + taskInfo.district }}</el-descriptions-item>
				<el-descriptions-item label="媒介列表">
					<template v-if="taskInfo.mediaType && taskInfo.mediaType.length > 10">
						<el-tooltip>
							<div slot="content">{{ taskInfo.mediaType }}</div>
							<div>{{ taskInfo.mediaType.substring(0, 10) + '...' }}</div>
						</el-tooltip>
					</template>
					<template v-else>{{ taskInfo.mediaType || '--' }}</template>
				</el-descriptions-item>
				<el-descriptions-item label="图片数量（张）">
					{{ taskInfo.itemCount || '--' }}
				</el-descriptions-item>
				<el-descriptions-item label="图片单价（元/张）">
					{{ taskInfo.itemPrice / 1000 || '--' }}
				</el-descriptions-item>
				<el-descriptions-item label="任务总金额（元）">
					{{ (taskInfo.itemCount * taskInfo.itemPrice) / 1000 }}
				</el-descriptions-item>
				<el-descriptions-item label="超时扣款比例">
					{{ taskInfo.timeoutCutMoney + '%' }}
				</el-descriptions-item>
				<el-descriptions-item label="二次采集奖励">
					{{ taskInfo.itemAgainMoney + '%' }}
				</el-descriptions-item>
				<el-descriptions-item label="自动完成比例">
					{{ taskInfo.finishTarget + '%' }}
				</el-descriptions-item>
				<el-descriptions-item label="任务模式">
					{{ taskInfo.type == 1 ? '抢单' : '指定' }}
				</el-descriptions-item>
				<el-descriptions-item label="指定人员">
					<el-button v-if="taskInfo.type == 2" type="text" @click="queryUserInfo(taskInfo.receiveUserCode)">{{ taskInfo.receiveUserCode }}</el-button>
					<span v-else>--</span>
				</el-descriptions-item>
				<el-descriptions-item label="拍摄时间">
					{{ taskInfo.beginTime + '~' + taskInfo.endTime }}
				</el-descriptions-item>
				<el-descriptions-item label="创建时间">
					{{ moment(taskInfo.addTime).format('YYYY-MM-DD HH:mm:ss') }}
				</el-descriptions-item>
				<el-descriptions-item label="领取时间">
					<template v-if="taskInfo.state != 1">
						{{ moment(taskInfo.receiveTime).format('YYYY-MM-DD HH:mm:ss') }}
					</template>
					<template v-else>--</template>
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions direction="vertical" border :column="5">
				<el-descriptions-item label="任务要求" :span="5">
					<div v-html="taskInfo.requirement"></div>
				</el-descriptions-item>
			</el-descriptions>
		</template>
		<el-row v-if="taskInfo.state == 2">
			<el-col :span="12">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>任务信息</span>
					</div>
					<el-row>
						<div class="amapBox" id="amapBox" style="width: 100%"></div>
					</el-row>
					<el-descriptions direction="vertical" border :column="5">
						<el-descriptions-item label="任务名称">{{ taskInfo.name || '--' }}</el-descriptions-item>
						<el-descriptions-item label="创建人">
							<el-button type="text" @click="queryUserInfo(taskInfo.createUserCode)">{{ taskInfo.createUserCode || '--' }}</el-button>
						</el-descriptions-item>
						<el-descriptions-item label="领取人">
							<template v-if="taskInfo.receiveUserCode && taskInfo.receiveUserCode != ''">
								<el-button type="text" @click="queryUserInfo(taskInfo.receiveUserCode)">{{ taskInfo.receiveUserCode }}</el-button>
							</template>
							<template v-else>--</template>
						</el-descriptions-item>
						<el-descriptions-item label="预计完成天数">{{ taskInfo.taskDays || '--' }}</el-descriptions-item>
						<el-descriptions-item label="发布区域">{{ taskInfo.province + taskInfo.city + taskInfo.district }}</el-descriptions-item>
						<el-descriptions-item label="媒介列表">
							<template v-if="taskInfo.mediaType && taskInfo.mediaType.length > 10">
								<el-tooltip>
									<div slot="content">{{ taskInfo.mediaType }}</div>
									<div>{{ taskInfo.mediaType.substring(0, 10) + '...' }}</div>
								</el-tooltip>
							</template>
							<template v-else>{{ taskInfo.mediaType || '--' }}</template>
						</el-descriptions-item>
						<el-descriptions-item label="图片数量（张）">
							{{ taskInfo.itemCount || '--' }}
						</el-descriptions-item>
						<el-descriptions-item label="图片单价（元/张）">
							{{ taskInfo.itemPrice / 1000 || '--' }}
						</el-descriptions-item>
						<el-descriptions-item label="任务总金额（元）">
							{{ (taskInfo.itemCount * taskInfo.itemPrice) / 1000 }}
						</el-descriptions-item>
						<el-descriptions-item label="超时扣款比例">
							{{ taskInfo.timeoutCutMoney + '%' }}
						</el-descriptions-item>
						<el-descriptions-item label="二次采集奖励">
							{{ taskInfo.itemAgainMoney + '%' }}
						</el-descriptions-item>
						<el-descriptions-item label="自动完成比例">
							{{ taskInfo.finishTarget + '%' }}
						</el-descriptions-item>
						<el-descriptions-item label="任务模式">
							{{ taskInfo.type == 1 ? '抢单' : '指定' }}
						</el-descriptions-item>
						<el-descriptions-item label="指定人员">
							<el-button v-if="taskInfo.type == 2" type="text" @click="queryUserInfo(taskInfo.receiveUserCode)">{{ taskInfo.receiveUserCode }}</el-button>
							<span v-else>--</span>
						</el-descriptions-item>
						<el-descriptions-item label="拍摄时间">
							{{ taskInfo.beginTime + '~' + taskInfo.endTime }}
						</el-descriptions-item>
						<el-descriptions-item label="创建时间">
							{{ moment(taskInfo.addTime).format('YYYY-MM-DD HH:mm:ss') }}
						</el-descriptions-item>
						<el-descriptions-item label="领取时间">
							<template v-if="taskInfo.state != 1">
								{{ moment(taskInfo.receiveTime).format('YYYY-MM-DD HH:mm:ss') }}
							</template>
							<template v-else>--</template>
						</el-descriptions-item>
					</el-descriptions>
					<el-descriptions direction="vertical" border :column="5">
						<el-descriptions-item label="任务要求" :span="5">
							<div v-html="taskInfo.requirement"></div>
						</el-descriptions-item>
					</el-descriptions>
				</el-card>
			</el-col>
			<el-col :span="12" class="progressBox">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>采集进度</span>
					</div>
					<template v-if="taskInfo.photoType == 1">
						<el-collapse border v-model="activePointId" @change="handlePointVosCollapseChange" accordion>
							<template v-if="pointDataList && pointDataList.length > 0">
								<el-collapse-item class="pointColItem" v-for="item in pointDataList" :key="item.pointId" :name="item.pointId">
									<template slot="title">
										<div class="titleSpan">
											<div class="dir"></div>
											<div>
												点位名称：{{ item.name || '--' }}
												<el-tag class="marginLeft-8" v-if="item.allIn" type="success" effect="dark" size="mini">该点位中的广告照片已全部审核</el-tag>
											</div>
										</div>
									</template>
									<el-button v-if="item.allIn" class="pointSubmitBtn" size="mini" type="primary" @click="itemSubmit(item.pointId)">提交</el-button>
									<el-descriptions border>
										<el-descriptions-item label="具体位置">{{ item.province + item.city + item.district + item.address }}</el-descriptions-item>
									</el-descriptions>
									<el-descriptions border v-if="item.url && item.url != ''">
										<el-descriptions-item labelClassName="pointImgLabel" label="点位照片" :span="3">
											<el-image lazy class="pointImg" @click="imgPreview(item.url)" :src="imgBaseUrl + item.url" />
										</el-descriptions-item>
									</el-descriptions>
									<el-collapse border v-model="activeMediaId" accordion>
										<template v-if="item.voList && item.voList.length > 0">
											<el-collapse-item v-for="(voItem, index) in item.voList" :key="index" :name="voItem.mediaId">
												<template slot="title">
													<div class="titleSpan">
														<div class="dir"></div>
														<div class="dir"></div>
														<div class="flex">
															媒介名称：{{ voItem.mediaType }}
															<div class="flex alignItemsCenter" v-if="voItem.allIn">
																<el-tag class="marginLeft-8 marginRight-8" type="success" effect="dark" size="mini">该媒介中的广告照片已全部审核</el-tag>
															</div>
														</div>
													</div>
												</template>
												<template v-if="voItem.dataList && voItem.dataList.length != 0">
													<el-descriptions
														:column="3"
														v-for="(advertiserItem, advertiserIndex) in voItem.dataList"
														:title="'广告主-' + (advertiserIndex + 1)"
														:key="advertiserIndex">
														<el-descriptions-item label="广告主名称">{{ advertiserItem.advertisersName || '--' }}</el-descriptions-item>
														<el-descriptions-item label="手机号">{{ advertiserItem.advertisersPhone || '--' }}</el-descriptions-item>
														<el-descriptions-item label="广告主行业">{{ advertiserItem.industryName || '--' }}</el-descriptions-item>
														<el-descriptions-item label="媒介主名称" :span="3">{{ advertiserItem.mediaOwnerName || '--' }}</el-descriptions-item>
														<el-descriptions-item label="审核说明" :span="3" content-class-name="reviewRemarkContent">
															<el-input
																class="advertiserItemmRemark"
																size="mini"
																:disabled="advertiserItem.collectReportState == 1"
																v-model="advertiserItem.reason"
																placeholder="若广告主信息有误，请在此说明"></el-input>
														</el-descriptions-item>
														<el-descriptions-item :span="3" label-class-name="phoneLabel" content-class-name="phoneContent" label="广告照片">
															<template>
																<el-row class="imgRowBox">
																	<template v-for="imgItem in advertiserItem.vos">
																		<div class="imgBox" :key="imgItem.flowId">
																			<img @click="imgPreview(imgItem.url)" :src="imgBaseUrl + imgItem.url" />
																			<el-tag
																				class="detailImgTag"
																				size="mini"
																				v-if="imgItem.detailImg && imgItem.detailImg != ''"
																				effect="dark"
																				@click="detailImgPreview(imgItem.detailImg)">
																				细节图片
																			</el-tag>
																			<el-checkbox
																				border
																				v-if="!imgItem.checkedResult && imgItem.reviewResults == 3"
																				class="checkBox"
																				v-model="imgItem.checked"
																				@change="taskInfoUpdata"></el-checkbox>
																			<i v-show="imgItem.checkedResult == 1 || imgItem.reviewResults == 1" class="el-icon-success successIcon"></i>
																			<el-tooltip placement="top" :content="imgItem.reason">
																				<i v-show="imgItem.checkedResult == 2 || imgItem.reviewResults == 2" class="el-icon-warning warnIcon"></i>
																			</el-tooltip>
																			<el-tooltip placement="top" content="疑似重复，点击查看详情">
																				<i
																					@click="repeatDataInfo(imgItem)"
																					v-show="
																						imgItem.repeatData && imgItem.repeatData.length != 0 && imgItem.checkedResult != 1 && imgItem.reviewResults != 1
																					"
																					class="el-icon-warning warnIcon warnIcon1"></i>
																			</el-tooltip>
																			<div class="imgItemReason" v-if="!imgItem.checkedResult && imgItem.reviewResults == 3">未审核</div>
																			<div class="imgItemReason-1" v-if="imgItem.checkedResult == 1 || imgItem.reviewResults == 1">
																				通过
																				<el-button
																					v-if="imgItem.reviewResults == 3"
																					class="marginLeft-16"
																					type="text"
																					size="mini"
																					@click="delReason(imgItem.flowId)">
																					修改
																				</el-button>
																			</div>
																			<div class="imgItemReason-2" v-if="imgItem.checkedResult == 2 || imgItem.reviewResults == 2">
																				拒绝
																				<el-button
																					v-if="imgItem.reviewResults == 3"
																					class="marginLeft-16"
																					type="text"
																					size="mini"
																					@click="delReason(imgItem.flowId)">
																					修改
																				</el-button>
																			</div>
																		</div>
																	</template>
																</el-row>
															</template>
														</el-descriptions-item>
													</el-descriptions>
													<template v-if="voItem.checkedFlag">
														<el-row class="confirmBox marginBottom-8">
															<template v-if="refuseItem">
																<div class="floatRight">
																	<el-button size="mini" type="primary" @click="regionConfirm(2)">确定</el-button>
																</div>
																<el-input
																	v-model="reasonNew"
																	size="mini"
																	style="width: 30%"
																	class="floatRight"
																	v-if="reason == '自定义原因'"
																	placeholder="请输入自定义原因"></el-input>
																<div class="floatRight">
																	<el-select size="mini" v-model="reason" placeholder="请选择原因">
																		<el-option v-for="(item, index) in reasonArr" :key="index" :label="item" :value="item"></el-option>
																		<el-option label="自定义原因" value="自定义原因"></el-option>
																	</el-select>
																</div>
															</template>
															<div class="floatRight marginRight-8">
																<el-button size="mini" type="danger" @click="refuseItem = true">拒绝</el-button>
															</div>
															<div class="floatRight">
																<el-button size="mini" type="primary" @click="regionConfirm(1)">通过</el-button>
															</div>
														</el-row>
													</template>
												</template>
											</el-collapse-item>
										</template>
									</el-collapse>
								</el-collapse-item>
							</template>
						</el-collapse>
					</template>
					<template v-else-if="taskInfo.photoType == 2 && taskInfo.esayCollectionVos && taskInfo.esayCollectionVos.length != 0">
						<el-row class="submitBox">
							<el-button type="primary" @click="esayCollectionSubmit">提交</el-button>
						</el-row>
						<el-row v-for="(item, index) in taskInfo.esayCollectionVos" :key="index" class="imgRow">
							<template v-if="item.collectReportState == 1">
								<el-col :span="14" class="imgCol">
									<el-image lazy fit="scale-down" v-if="item.url.split('.')[1] != 'mp4'" :src="imgBaseUrl + item.url" @click="imgPreview(item.url)" />
									<video controls v-else :src="imgBaseUrl + item.url" class="video"></video>
									<el-tag size="mini" class="fileType" effect="dark">{{ item.url.split('.')[1] != 'mp4' ? '图片' : '视频' }}</el-tag>
									<i v-show="item.reviewResults == 1" class="el-icon-success successIcon"></i>
									<el-tooltip placement="top" :content="item.reviewComments">
										<i v-show="item.reviewResults == 2" class="el-icon-warning warnIcon"></i>
									</el-tooltip>
									<div class="index">{{ index + 1 }}</div>
								</el-col>
								<el-col :span="10" class="imgReview">
									<el-form label-width="100px">
										<el-form-item label="图片审核">
											<span>{{ item.reviewResults == 1 ? '通过' : '拒绝' }}</span>
										</el-form-item>
										<template v-if="item.reviewResults == 1">
											<el-form-item label="点位名称">
												<span>{{ item.pointName }}</span>
											</el-form-item>
											<el-form-item label="广告主名称" required>
												<span>{{ item.advertisersName }}</span>
											</el-form-item>
											<el-form-item label="广告主行业" required>
												<span>{{ item.industryName }}</span>
											</el-form-item>
											<el-form-item label="广告主电话">
												<span>{{ item.advertisersPhone }}</span>
											</el-form-item>
											<el-form-item label="媒介类型">
												<span>{{ item.mediaName }}</span>
											</el-form-item>
											<el-form-item label="媒介主名称">
												<span>{{ item.mediaOwnerName }}</span>
											</el-form-item>
										</template>
										<template v-if="item.reviewResults == 2">
											<el-form-item label="拒绝理由">
												<span>{{ item.reviewComments }}</span>
											</el-form-item>
										</template>
									</el-form>
								</el-col>
							</template>
							<template v-else>
								<el-col :span="14" class="imgCol">
									<el-image lazy fit="scale-down" v-if="item.url.split('.')[1] != 'mp4'" :src="imgBaseUrl + item.url" @click="imgPreview(item.url)" />
									<video controls v-else :src="imgBaseUrl + item.url" class="video"></video>
									<el-tag size="mini" class="fileType" effect="dark">{{ item.url.split('.')[1] != 'mp4' ? '图片' : '视频' }}</el-tag>
									<div class="index">{{ index + 1 }}</div>
								</el-col>
								<el-col :span="10" class="imgReview">
									<el-form label-width="100px">
										<el-form-item label="审核">
											<el-radio-group v-model="item.reviewResults">
												<el-radio :label="1">通过</el-radio>
												<el-radio :label="2">拒绝</el-radio>
											</el-radio-group>
										</el-form-item>
										<template v-if="item.reviewResults == 1">
											<el-form-item label="点位名称" required>
												<el-autocomplete
													size="mini"
													style="width: 100%"
													v-model="item.pointName"
													value-key="name"
													:fetch-suggestions="queryPointNameSearchAsync"
													placeholder="请输入点位名称"
													@focus="pointNameInputFocus(item.flowId)"
													@select="handlePointNameSelect"
													@input="pointNameChange()"></el-autocomplete>
											</el-form-item>
											<el-form-item label="广告主名称" required>
<!--												<el-autocomplete-->
<!--													size="mini"-->
<!--													style="width: 100%"-->
<!--													v-model="item.advertisersName"-->
<!--													value-key="advertisersName"-->
<!--													placeholder="请输入广告主名称"-->
<!--													@focus="advertisersNameInputFocus(item.flowId)"-->
<!--													@select="handleSelect"-->
<!--													@input="advertisersNameChange()"></el-autocomplete>-->
												<el-input v-model="item.advertisersName" size="mini" style="width: 100%" placeholder="请输入广告主名称"></el-input>
											</el-form-item>
											<el-form-item label="广告主行业" required>
												<el-select filterable v-model="item.industryName" size="mini" style="width: 100%" placeholder="请选择行业">
													<el-option v-for="item in industryNameList" :key="item.flowId" :label="item.value" :value="item.value"></el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="广告主电话">
												<el-input v-model="item.advertisersPhone" size="mini" placeholder="请输入广告主电话"></el-input>
											</el-form-item>
											<el-form-item label="媒介类型" required>
												<el-select filterable v-model="item.mediaName" size="mini" style="width: 100%" placeholder="请选择媒介类型">
													<el-option v-for="(item, index) in mediaNameTypeList" :key="index" :label="item" :value="item"></el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="媒介主名称">
												<el-input v-model="item.mediaOwnerName" size="mini" placeholder="请输入媒介主名称"></el-input>
											</el-form-item>
										</template>
										<template v-if="item.reviewResults == 2">
											<el-form-item label="拒绝理由">
												<el-select size="mini" v-model="item.reason" placeholder="请选择原因">
													<el-option v-for="(item, index) in reasonArr" :key="index" :label="item" :value="item"></el-option>
													<el-option label="自定义原因" value="自定义原因"></el-option>
												</el-select>
											</el-form-item>
											<el-form-item v-if="item.reason == '自定义原因'" label="自定义原因">
												<el-input v-model="item.reasonNew" size="mini" placeholder="请输入自定义原因"></el-input>
											</el-form-item>
										</template>
									</el-form>
								</el-col>
							</template>
						</el-row>
					</template>
				</el-card>
			</el-col>
		</el-row>
		<div slot="footer" class="dialog-footer" v-if="taskInfo.state == 2 && userInfo.userType == 1">
			<el-button type="primary" @click="submit">强制提交</el-button>
		</div>
		<Vviewer ref="viewer"></Vviewer>
		<user-info :visible="drawerVisible" :userCode="userCode" @closeDrawer="closeDrawer" />
		<VideoPreview :dialogVisible="VideoPreviewShow" :videoUrl="videoUrl" @dialogClosed="VideoPreviewClose" />
		<RepeatDataReview :visible="repeatDataReviewVisible" :repeatData="repeatData" @repeatDataReviewClose="repeatDataReviewClose" />
		<el-dialog append-to-body title="强制提交提示" :visible.sync="submitConfirmVisible" width="30%" :before-close="submitCancel">
			<div class="submitConfirmText">若执行强制提交操作，则该任务将被强制进入审核流程，并且不会进行价格结算。</div>
			<div class="submitConfirmText warningText">建议与该任务采集员协商后再进行此操作！</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="submitConfirmVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitConfirm">确 定</el-button>
			</span>
		</el-dialog>
	</el-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import { queryTaskInfo, queryDatapointCollectionInfo, regionalAdd, reviewDataByReport, getAdvertiserList, getIndustryNameList, reviewAppDataByReport } from '@/api/task';
import { getDatapointList } from '@/api/statisticalReport';
import { TextToCode } from 'element-china-area-data';
import moment from 'moment';
import UserInfo from '@/components/UserInfo';
import AMapLoader from '@amap/amap-jsapi-loader';
import Vviewer from '@/components/Vviewer';
import VideoPreview from '@/components/VideoPreview';
import RepeatDataReview from './RepeatDataReview.vue';
import { debounce } from '@/utils';
export default {
	components: { UserInfo, Vviewer, VideoPreview, RepeatDataReview },
	props: ['taskInfoVisible', 'op'],
	data() {
		return {
			moment,
			userCode: null,
			drawerVisible: false,
			taskInfo: {},
			map: null,
			pointDataList: [],
			activeMediaId: '',
			activePointId: '',
			VideoPreviewShow: false,
			videoUrl: '',
			submitConfirmVisible: false,
			refuseItem: false,
			repeatDataReviewVisible: false,
			repeatData: null,
			reasonNew: '',
			reason: '',
			reasonArr: ['图片模糊', '图片光线太暗', '图片有遮挡物', '图片反光严重'],
			currentAdNameInputFocus: null,
			currentPointNameInputFocus: null,
			mediaNameTypeList: [],
			industryNameList: [],
		};
	},
	computed: {
		imgBaseUrl() {
			return process.env.VUE_APP_RESOURCES_HOST;
		},
		...mapGetters(['userInfo']),
	},
	watch: {
		taskInfoVisible(data) {
			if (data) {
				this.getTaskInfo();
			}
		},
	},
	methods: {
		advertisersNameChange() {
			let unreadImgList = [...this.taskInfo.esayCollectionVos];
			unreadImgList.map(item => {
				if (item.flowId == this.currentAdNameInputFocus && item.advertisersName == '') {
					item.recordId = '';
				}
			});
			this.unreadImgList = unreadImgList;
		},
		pointNameChange() {
			let unreadImgList = [...this.taskInfo.esayCollectionVos];
			unreadImgList.map(item => {
				if (item.flowId == this.currentPointNameInputFocus && item.pointName == '') {
					item.pointId = '';
				}
			});
			this.taskInfo.esayCollectionVos = unreadImgList;
		},
		esayCollectionSubmit: debounce(function () {
			if (this.taskInfo.esayCollectionVos && this.taskInfo.esayCollectionVos.length == 0) {
				this.$message({
					message: '暂无采集数据，请勿提交！',
					type: 'warning',
				});
				return;
			}
			let unreadImgList = this.taskInfo.esayCollectionVos.filter(item => item.collectReportState == 0);
			if (unreadImgList.length == 0) {
				this.$message({
					message: '暂无未审核的采集数据，请勿提交！',
					type: 'warning',
				});
				return;
			}
			let passIds = [];
			let rejectIds = [];
			const _this = this;
			unreadImgList.map(item => {
				if (item.collectReportState == 0) {
					if (item.reviewResults == 1) {
						let obj = {
							flowId: item.flowId,
							pointName: item.pointName,
							pointId: item.pointId || '',
							mediaName: item.mediaName,
							recordId: item.recordId,
							industryName: item.industryName,
							advertisersName: item.advertisersName,
							advertisersPhone: item.advertisersPhone,
							mediaOwnerName: item.mediaOwnerName,
						};
						passIds.push(obj);
					} else if (item.reviewResults == 2) {
						let obj = {
							flowId: item.flowId,
							reason: item.reason == '自定义原因' ? item.reasonNew : item.reason,
						};
						rejectIds.push(obj);
					}
				}
			});
			let submitFlag = true;
			let submitType = '';
			let imgFlowId = '';
			passIds.map(item => {
				if (!item.pointName || item.pointName == '') {
					submitType = 'pointName';
					submitFlag = false;
					imgFlowId = item.flowId;
				}
				if (!item.advertisersName || item.advertisersName == '') {
					submitType = 'advertisersName';
					submitFlag = false;
					imgFlowId = item.flowId;
				}
				if (!item.industryName || item.industryName == '') {
					submitType = 'industryName';
					submitFlag = false;
					imgFlowId = item.flowId;
				}
				if (!item.mediaName || item.mediaName == '') {
					submitType = 'mediaName';
					submitFlag = false;
					imgFlowId = item.flowId;
				}
			});
			rejectIds.map(item => {
				if (!item.reason || item.reason == '') {
					submitType = 'reason';
					submitFlag = false;
					imgFlowId = item.flowId;
				}
			});
			if (!submitFlag) {
				let index = 0;
				unreadImgList.map((imgItem, imgIndex) => {
					if (imgItem.flowId == imgFlowId) {
						index = imgIndex;
					}
				});
				if (submitType == 'pointName') {
					this.$message({
						message: '第' + (index + 1) + '张图片缺少点位名称！',
						type: 'warning',
					});
				}
				if (submitType == 'advertisersName') {
					this.$message({
						message: '第' + (index + 1) + '张图片缺少广告主名称！',
						type: 'warning',
					});
				}
				if (submitType == 'industryName') {
					this.$message({
						message: '第' + (index + 1) + '张图片缺少广告主行业！',
						type: 'warning',
					});
				}
				if (submitType == 'reason') {
					this.$message({
						message: '第' + (index + 1) + '张图片缺少拒绝理由！',
						type: 'warning',
					});
				}
				if (submitType == 'mediaName') {
					this.$message({
						message: '第' + (index + 1) + '张图片缺少媒介类型！',
						type: 'warning',
					});
				}
				return;
			}
			let params = {
				taskId: this.op,
				passIds,
				rejectIds,
				adCode: this.taskInfo.adCode,
			};
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			reviewAppDataByReport(params)
				.then(res => {
					loading.close();
					_this.getTaskInfo();
				})
				.catch(err => {
					loading.close();
					console.log(err);
				});
		}, 300),
		getIndustryList() {
			let params = {
				type: 3,
			};
			const _this = this;
			getIndustryNameList(params)
				.then(res => {
					_this.industryNameList = res.data.dataList;
				})
				.catch(err => {
					console.log(err);
				});
		},
		handlePointNameSelect() {
			if (this.currentPointNameInputFocus) {
				let unreadImgList = [...this.taskInfo.esayCollectionVos];
				unreadImgList.map(item => {
					if (item.flowId == this.currentPointNameInputFocus) {
						item.pointId = data.pointId;
						item.pointName = data.name;
					}
				});
				this.taskInfo.esayCollectionVos = unreadImgList;
				this.currentPointNameInputFocus = null;
			}
		},
		handleSelect(data) {
			if (this.currentAdNameInputFocus) {
				let unreadImgList = [...this.taskInfo.esayCollectionVos];
				unreadImgList.map(item => {
					if (item.flowId == this.currentAdNameInputFocus) {
						item.recordId = data.flowId;
						item.advertisersPhone = data.advertisersPhone;
						item.industryName = data.industryName;
						item.mediaOwnerName = data.mediaOwnerName;
					}
				});
				this.taskInfo.esayCollectionVos = unreadImgList;
				this.currentAdNameInputFocus = null;
			}
		},
		pointNameInputFocus(pointId) {
			this.currentPointNameInputFocus = pointId;
		},
		createPointNameFilter(queryString) {
			return item => {
				return item.name.toLowerCase().indexOf(queryString.toLowerCase()) == 0;
			};
		},
		queryPointNameSearchAsync(queryString, cb) {
			let params = {};
			getDatapointList(params)
				.then(res => {
					let results = queryString ? res.data.dataList.filter(this.createPointNameFilter(queryString)) : res.data.dataList;
					cb(results);
				})
				.catch(err => {
					console.log(err);
				});
		},
		advertisersNameInputFocus(flowId) {
			this.currentAdNameInputFocus = flowId;
		},
		// querySearchAsync(queryString, cb) {
		// 	let params = {};
		// 	getAdvertiserList(params)
		// 		.then(res => {
		// 			let results = queryString ? res.data.dataList.filter(this.createAdvertiserFilter(queryString)) : res.data.dataList;
		// 			cb(results);
		// 		})
		// 		.catch(err => {
		// 			console.log(err);
		// 		});
		// },
		createAdvertiserFilter(queryString) {
			return item => {
				return item.advertisersName.toLowerCase().indexOf(queryString.toLowerCase()) == 0;
			};
		},
		delReason(flowId) {
			let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
			let activePointId = this.activePointId;
			let activeMediaId = this.activeMediaId;
			pointDataList.map(pointItem => {
				if (activePointId == pointItem.pointId) {
					pointItem.voList.map((voItem, voIndex) => {
						if (voItem.mediaId == activeMediaId) {
							voItem.dataList.map(advertiserItem => {
								advertiserItem.vos.map(imgItem => {
									if (imgItem.flowId == flowId) {
										delete imgItem.reason;
										delete imgItem.checkedResult;
										imgItem.checked = false;
									}
								});
							});
						}
					});
				}
			});
			this.pointDataList = pointDataList;
			this.updataList();
		},
		repeatDataReviewClose(type) {
			this.repeatDataReviewVisible = false;
			if (type == 1 || type == 2) {
				let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
				let activePointId = this.activePointId;
				let activeMediaId = this.activeMediaId;
				pointDataList.map(pointItem => {
					if (activePointId == pointItem.pointId) {
						pointItem.allIn = true;
						pointItem.voList.map((voItem, voIndex) => {
							if (voItem.mediaId == activeMediaId) {
								voItem.allIn = true;
								voItem.dataList.map(advertiserItem => {
									advertiserItem.vos.map(imgItem => {
										if (imgItem.flowId == this.repeatData.flowId && type == 2 && (!imgItem.checkedResult || imgItem.checkedResult == 3)) {
											imgItem.checkedResult = 2;
											imgItem.reason = '重复照片';
										} else if (imgItem.flowId == this.repeatData.flowId && type == 1 && (!imgItem.checkedResult || imgItem.checkedResult == 3)) {
											imgItem.checkedResult = 1;
											imgItem.reason = '通过';
										}
										if (!imgItem.checkedResult) {
											voItem.allIn = false;
										}
									});
								});
								voItem.checkedFlag = false;
							}
							if (!voItem.allIn) {
								pointItem.allIn = false;
							}
						});
					}
				});
				this.pointDataList = pointDataList;
				this.taskInfo.unPointVos = pointDataList;
			}
			this.repeatData = null;
		},
		repeatDataInfo(data) {
			this.repeatDataReviewVisible = true;
			this.repeatData = data;
		},
		itemSubmit: debounce(function (pointId) {
			const _this = this;
			let params = {
				passIds: [],
				rejectIds: [],
			};
			let passIds = [];
			let rejectIds = [];
			let passAdIds = [];
			let rejectAdIds = [];
			let activeMediaId = '';
			let activePointId = '';
			let flag = true;
			let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
			pointDataList.map(pointItem => {
				if (pointItem.voList && pointItem.voList.length != 0) {
					if (pointItem.pointId == pointId) {
						pointItem.voList.map((voItem, voIndex) => {
							voItem.dataList.map(advertiserItem => {
								advertiserItem.vos.map(imgItem => {
									if (imgItem.reviewResults == 3) {
										if (imgItem.checkedResult == 2 && (imgItem.reason == '' || !imgItem.reason)) {
											flag = false;
											activeMediaId = voItem.mediaId;
											activePointId = pointItem.pointId;
										}
										if (imgItem.checkedResult == 2) {
											let obj = {
												flowId: imgItem.flowId,
												reason: imgItem.reason,
											};
											rejectIds.push(obj);
										} else if (imgItem.checkedResult == 1) {
											passIds.push(imgItem.flowId);
										}
									}
								});
								if (advertiserItem.reason && advertiserItem.reason != '') {
									let obj = {
										flowId: advertiserItem.advertisersId,
										reason: advertiserItem.reason,
									};
									rejectAdIds.push(obj);
								} else {
									passAdIds.push(advertiserItem.advertisersId);
								}
							});
						});
					}
				}
			});
			if (!flag) {
				this.$message({
					message: '请选择不通过的原因',
					type: 'warning',
				});
				_this.activeMediaId = activeMediaId;
				_this.activePointId = activePointId;
				return;
			}
			params.passIds = passIds;
			params.rejectIds = rejectIds;
			params.passAdIds = passAdIds;
			params.rejectAdIds = rejectAdIds;
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});

			reviewDataByReport(params)
				.then(res => {
					loading.close();
					_this.getTaskInfo();
				})
				.catch(err => {
					loading.close();
					console.log(err);
				});
		}, 300),
		updataList(type) {
			let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
			let activePointId = this.activePointId;
			let activeMediaId = this.activeMediaId;
			pointDataList.map(pointItem => {
				if (activePointId == pointItem.pointId) {
					pointItem.allIn = true;
					pointItem.voList.map((voItem, voIndex) => {
						if (voItem.mediaId == activeMediaId) {
							voItem.allIn = true;
							voItem.dataList.map(advertiserItem => {
								advertiserItem.vos.map(imgItem => {
									if (imgItem.checked && type == 2 && (!imgItem.checkedResult || imgItem.checkedResult == 3)) {
										imgItem.checkedResult = 2;
										if (this.reason == '自定义原因') {
											imgItem.reason = this.reasonNew;
										} else {
											imgItem.reason = this.reason;
										}
									} else if (imgItem.checked && type == 1 && (!imgItem.checkedResult || imgItem.checkedResult == 3)) {
										imgItem.checkedResult = 1;
										imgItem.reason = '通过';
									}
									if (!imgItem.checkedResult) {
										voItem.allIn = false;
									}
								});
							});
							voItem.checkedFlag = false;
						}
						if (!voItem.allIn) {
							pointItem.allIn = false;
						}
					});
				}
			});
			this.pointDataList = pointDataList;
			this.taskInfo.unPointVos = pointDataList;
			this.reasonNew = '';
			this.reason = '';
			this.refuseItem = false;
			this.repeatData = null;
		},
		regionConfirm(type) {
			if (type == 2) {
				if (this.reason == '') {
					this.$message({
						message: '请选择原因',
						type: 'warning',
					});
					return;
				}
				if (this.reason == '自定义原因') {
					let flag = false;
					let reasonArr = this.reasonArr;
					if (this.reasonNew == '') {
						this.$message({
							message: '输入自定义原因',
							type: 'warning',
						});
						return;
					}
					reasonArr.map(item => {
						if (item == this.reasonNew) {
							flag = true;
						}
					});
					// flag = true表示该自定义原因与枚举值列表中的某一项重复,如果为false则枚举值列表中没有此自定义原因，则往枚举值列表添加
					if (!flag) {
						reasonArr.splice(0, 0, this.reasonNew);
					}
				}
			}
			// 将原因更新到图片信息中
			this.updataList(type);
		},
		taskInfoUpdata() {
			let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
			pointDataList.map(pointItem => {
				if (pointItem.voList && pointItem.voList.length != 0) {
					pointItem.voList.map((voItem, voIndex) => {
						voItem.checkedFlag = false;
						this.refuseItem = false;
						voItem.dataList.map(advertiserItem => {
							advertiserItem.vos.map(imgItem => {
								if (imgItem.checked && !imgItem.checkedResult) {
									voItem.checkedFlag = true;
								}
							});
						});
					});
				}
			});
			this.pointDataList = pointDataList;
		},
		submitCancel() {
			this.submitConfirmVisible = false;
		},
		submitConfirm: debounce(function () {
			const _this = this;
			let data = {
				taskId: _this.op,
			};
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			regionalAdd(data)
				.then(res => {
					loading.close();
					_this.$message({
						message: '操作成功',
						type: 'success',
					});
					_this.submitConfirmVisible = false;
					_this.closeDialog(true);
				})
				.catch(err => {
					loading.close();
					console.log(err);
				});
		}, 300),
		submit() {
			this.submitConfirmVisible = true;
		},
		VideoPreviewClose() {
			this.videoUrl = '';
			this.VideoPreviewShow = false;
		},
		detailImgPreview(urlStr) {
			const _this = this;
			let list = urlStr.split(',');
			let imgList = [];

			list.map(item => {
				let obj = {
					thumbnail: _this.imgBaseUrl + item,
					source: _this.imgBaseUrl + item,
				};
				imgList.push(obj);
			});
			this.$refs.viewer.show(imgList);
		},
		imgPreview(url) {
			const _this = this;
			let type = url.split('.')[1];
			if (type == 'mp4') {
				this.videoUrl = this.imgBaseUrl + url;
				this.VideoPreviewShow = true;
			} else {
				this.$refs.viewer.show([
					{
						thumbnail: _this.imgBaseUrl + url,
						source: _this.imgBaseUrl + url,
					},
				]);
			}
		},
		handlePointVosCollapseChange() {
			if (!this.activePointId || this.activePointId == '') {
				return;
			}
			let voList = [];
			let taskInfo = this.taskInfo;
			let pointDataList = [];
			pointDataList = taskInfo.pointVos;
			pointDataList.map(item => {
				if (item.pointId == this.activePointId) {
					voList = item.voList;
				}
			});
			if (!voList || voList.length == 0) {
				this.getVoList(this.activePointId);
			} else {
				this.pointDataList = pointDataList;
				this.activeMediaId = voList[0].mediaId;
			}
		},
		initMap(AMap) {
			this.map = new AMap.Map('amapBox');
			this.mapUpdate(TextToCode[this.taskInfo.province][this.taskInfo.city][this.taskInfo.district].code);
			if (this.taskInfo.coordinate && this.taskInfo.coordinate != '') {
				this.updataMap(this.taskInfo.coordinate);
			}
		},
		closeDrawer() {
			this.drawerVisible = false;
			this.userCode = null;
		},
		queryUserInfo(userCode) {
			this.userCode = userCode;
			this.drawerVisible = true;
		},
		getTaskInfo() {
			const _this = this;
			let params = {
				taskId: this.op,
			};
			const loading = _this.$loading({
				lock: true,
				text: '加载中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			queryTaskInfo(params)
				.then(res => {
					_this.createMap();
					loading.close();
					_this.taskInfo = res.data;
					_this.taskInfo.mediaType = _this.taskInfo.mediaType.join(',');
					let mediaTypeList = JSON.parse(JSON.stringify(_this.taskInfo.mediaType));
					_this.mediaNameTypeList = mediaTypeList.split(',');
					_this.getIndustryList();
					if (_this.taskInfo.photoType == 1) {
						let activePointId = res.data.pointVos[0].pointId;
						_this.activePointId = activePointId;
						_this.getVoList(activePointId);
					} else if (_this.taskInfo.photoType == 2) {
						if (_this.taskInfo.esayCollectionVos && _this.taskInfo.esayCollectionVos.length != 0) {
							_this.taskInfo.esayCollectionVos.sort(_this.sort);
						}
					}
				})
				.catch(() => {
					loading.close();
				});
		},
		sort(a, b) {
			return a.collectReportState - b.collectReportState;
		},
		createMap() {
			const _this = this;
			AMapLoader.load({
				key: 'a68cfa9e236bde4322550467cef9a13b', // 申请好的Web端开发者Key，首次调用 load 时必填
				version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
				plugins: ['AMap.DistrictSearch'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				AMapUI: {
					// 是否加载 AMapUI，缺省不加载
					version: '1.1', // AMapUI 缺省 1.1
					plugins: [], // 需要加载的 AMapUI ui插件
				},
			})
				.then(AMap => {
					_this.$nextTick(() => _this.initMap(AMap));
				})
				.catch(e => {
					console.error(e);
				});
		},
		getVoList(activePointId) {
			let params = {
				pointId: activePointId,
				taskId: this.taskInfo.taskId,
			};
			const _this = this;
			queryDatapointCollectionInfo(params)
				.then(res => {
					let taskInfo = _this.taskInfo;
					let pointDataList = taskInfo.pointVos;
					pointDataList.map(item => {
						item.voList = [];
						if (item.pointId == res.data.pointId) {
							item.voList = res.data.voList;
							_this.activeMediaId = res.data.voList[0].mediaId;
						}
						item.voList.map((voItem, voIndex) => {
							voItem.dataList.map(advertiserItem => {
								advertiserItem.vos.map(imgItem => {
									if (imgItem.reviewResults == 1 || imgItem.reviewResults == 2) {
										imgItem.checkedResult = imgItem.reviewResults;
									}
								});
							});
							voItem.dataList.sort(_this.sort);
						});
					});
					_this.pointDataList = pointDataList;
				})
				.catch(err => {
					console.log(err);
				});
		},
		updataMap(data) {
			let pointList = JSON.parse(data);
			if (pointList.length == 0) {
				return;
			}
			let polygonList = pointList.map(item => {
				return [item.longitude, item.latitude];
			});
			let polygon = new AMap.Polygon({
				path: polygonList,
			});
			this.map.add([polygon]);
			this.map.setFitView();
		},
		mapUpdate(cityCode) {
			var _this = this;
			if (_this.polygons && _this.polygons.length != 0) {
				_this.map.remove(_this.polygons);
			}
			//加载行政区划插件
			if (!_this.district) {
				//实例化DistrictSearch
				var opts = {
					subdistrict: 0, //获取边界不需要返回下级行政区
					extensions: 'all', //返回行政区边界坐标组等具体信息
					level: 'city', //查询行政级别为 市
				};
				_this.district = new AMap.DistrictSearch(opts);
			}
			//行政区查询
			_this.district.search(cityCode, function (status, result) {
				_this.polygons = [];
				var bounds = result.districtList[0].boundaries;
				if (bounds) {
					for (var i = 0, l = bounds.length; i < l; i++) {
						//生成行政区划polygon
						var polygon = new AMap.Polygon({
							strokeWeight: 2,
							path: bounds[i],
							fillOpacity: 0.4,
							fillColor: '#80d8ff',
							strokeColor: '#0091ea',
						});
						_this.polygons.push(polygon);
					}
				}
				_this.map.add(_this.polygons);
				_this.map.setFitView(_this.polygons); //视口自适应
			});
		},
		closeDialog(flag) {
			if (this.map) {
				this.map.destroy();
				this.map = null;
			}
			this.activePointId = '';
			this.activeMediaId = '';
			this.pointDataList = [];
			this.taskInfo = {};
			this.$emit('taskInfoClose', flag);
		},
	},
};
</script>
<style lang="less" scoped>
.submitBox {
	display: flex;
	justify-content: flex-end;
}
.imgReview {
	/deep/ .el-form-item {
		margin-bottom: 2px;
	}
}
/deep/ .reviewRemarkContent {
	width: 85%;
	.advertiserItemmRemark {
		width: 100%;
	}
	.advertiserItemmRemark2 {
		width: 86%;
	}
	.advertiserItemReasonSubmitBtn {
		width: 14%;
	}
}
.submitConfirmText {
	font-size: 16px;
	line-height: 30px;
}
.warningText {
	color: red;
	margin-top: 16px;
	line-height: unset;
}
.clearfix {
	span {
		font-size: 20px;
		font-weight: bold;
	}
}
/deep/ .el-dialog__body {
	padding-top: 0;
}
/deep/ .phoneLabel {
	width: 12%;
}
/deep/ .phoneContent {
	width: 88%;
}
.imgRowBox {
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	.imgBox {
		width: 150px;
		height: 150px;
		margin-right: 8px;
		position: relative;
		display: inline-block;
		.imgItemReason,
		.imgItemReason-1,
		.imgItemReason-2 {
			font-size: 12px;
			width: 100%;
			.el-button {
				padding: 0;
			}
		}
		.imgItemReason-1 {
			color: #52c41a;
		}
		.imgItemReason-2 {
			color: #f5222d;
		}
		.warnIcon {
			position: absolute;
			right: 0;
			top: 0;
			color: #f5222d;
			font-size: 28px;
			cursor: pointer;
		}
		.successIcon {
			position: absolute;
			right: 0;
			top: 0;
			color: #52c41a;
			font-size: 28px;
			cursor: pointer;
		}
		.detailImgTag {
			position: absolute;
			right: 4px;
			top: 4px;
			cursor: pointer;
		}
		.checkBox {
			position: absolute;
			left: 0;
			top: 0;
			border: 2px solid #ccc;
			padding: 4px;
			height: auto;
		}
		/deep/ .checkBox.is-checked {
			border-color: #409eff;
		}
		img {
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}
}

.row {
	padding: 0 32px;
	.el-col {
		font-size: 16px;
	}
}
.progressBox {
	padding: 0 16px;
	max-height: 1000px;
	overflow-y: auto;
	.imgRow {
		padding: 8px 0;
		border-bottom: 1px solid #cccccc;
		.imgCol {
			height: 300px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			border: 1px solid #eee;
			.fileType {
				position: absolute;
				right: 4px;
				bottom: 4px;
			}
			.video {
				width: 100%;
				height: 100%;
				background-color: #000;
			}
			.successIcon {
				position: absolute;
				right: 0;
				top: 0;
				color: #52c41a;
				font-size: 28px;
				cursor: pointer;
			}
			.warnIcon {
				position: absolute;
				right: 0;
				top: 0;
				color: #f5222d;
				font-size: 28px;
				cursor: pointer;
			}
			.index {
				width: 30px;
				height: 30px;
				background-color: rgb(0, 145, 255);
				border-radius: 50%;
				position: absolute;
				left: 8px;
				top: 8px;
				text-align: center;
				line-height: 30px;
				color: #ffffff;
			}
			.el-image {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
		}
	}
	.imgRow:last-child {
		padding-bottom: 0;
		border-bottom: none;
	}
	.imgRow:first-child {
		padding-top: 0;
	}
}
/deep/ .pointImgLabel {
	width: 150px;
}
.pointImg {
	width: 100px;
	height: 80px;
	cursor: pointer;
	// img{}
}
.pointColItem {
	position: relative;
	.pointSubmitBtn {
		position: absolute;
		right: 28px;
		top: 8px;
	}
}
.titleSpan {
	font-size: 16px;
	font-weight: 700;
	display: flex;
	align-items: center;
	& > div {
		display: inline-block;
	}
	div.dir {
		width: 0;
		height: 20px;
		border: 2px solid #66b1ff;
		margin-right: 8px;
	}
}
/deep/ .el-collapse-item__content {
	padding-bottom: 0;
}
.amapBox {
	height: 300px;
}
</style>
