<template>
    <el-drawer append-to-body :close-on-press-escape="false" size="50%" title="疑似照片详情" :wrapperClosable="false" :visible="visible" :before-close="closeDialog">
        <el-descriptions border>
            <el-descriptions-item labelClassName="pointImgLabel" label="当前照片">
                <img class="pointImg" @click="imgPreview(info.url)" :src="imgBaseUrl + info.url" />
            </el-descriptions-item>
        </el-descriptions>
        <el-descriptions border>
            <el-descriptions-item labelClassName="pointImgLabel" label="重复照片">
                <template v-for="item in info.repeatData">
                    <img :key="item.flowId" class="pointImg" @click="imgPreview(item.url)" :src="imgBaseUrl + item.url" />
                </template>
            </el-descriptions-item>
        </el-descriptions>
        <el-row class="flex justifyContent-end paddingRight-16 paddingTop-16">
            <el-button type="danger" @click="submit(2)">拒绝</el-button>
            <el-button type="primary" @click="submit(1)">通过</el-button>
        </el-row>
        <Vviewer ref="viewer"></Vviewer>
    </el-drawer>
</template>
<script>
import Vviewer from "@/components/Vviewer";
export default {
    components: { Vviewer },
    props: ["visible", "repeatData"],
    data() {
        return {
            info: {},
        };
    },
    computed: {
        imgBaseUrl() {
            return process.env.VUE_APP_RESOURCES_HOST;
        },
    },
    watch: {
        visible(visibleData) {
            if (visibleData && this.repeatData) {
                this.info = this.repeatData;
            }
        },
    },
    methods: {
        submit(type) {
            this.info = {};
            this.$emit("repeatDataReviewClose", type);
        },
        imgPreview(url) {
            const _this = this;
            this.$refs.viewer.show([
                {
                    thumbnail: _this.imgBaseUrl + url,
                    source: _this.imgBaseUrl + url,
                },
            ]);
        },
        closeDialog() {
            this.info = {};
            this.$emit("repeatDataReviewClose");
        },
    },
};
</script>
<style lang="less" scoped>
.pointImgLabel {
    width: 100px;
}
.pointImg {
    width: 100px;
    height: 80px;
    cursor: pointer;
}
</style>
