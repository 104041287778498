<template>
	<el-drawer :close-on-press-escape="false" size="60%" title="审核任务" :wrapperClosable="false" :visible="taskReviewVisible" :before-close="closeDialog">
		<template v-if="taskInfo.isSubmit == 1 || (taskInfo.isSubmit == 0 && taskInfo.taskProcedureState != 1)">
			<el-row class="marginBottom-16 row">
				<el-col :span="6">任务模式：{{ taskInfo.type == 1 ? '抢单' : taskInfo.type == 2 ? '指定' : '--' }}</el-col>
				<el-col :span="6">
					领取人：
					<el-button style="padding: 0" size="mini" type="text" @click="queryUserInfo(taskInfo.receiveUserCode)">{{ taskInfo.receiveUserCode || '--' }}</el-button>
				</el-col>
				<el-col :span="6">领取任务日期：{{ moment(taskInfo.receiveTime).format('YYYY年MM月DD日') }}</el-col>
				<el-col :span="6">任务完成天数：{{ taskInfo.taskDays }} 天</el-col>
			</el-row>
			<el-row class="marginBottom-16 row">
				<el-col :span="6">任务总数量：{{ taskInfo.totalItemCount || 0 }}</el-col>
				<el-col :span="6">拍摄总数量：{{ taskInfo.totalCount || 0 }}</el-col>
				<el-col :span="6">已通过数量：{{ passCount }}</el-col>
				<el-col :span="6">已驳回数量：{{ failCount }}</el-col>
			</el-row>
			<el-row v-if="cutDayCount > 0" class="marginBottom-16 row">
				<el-col :span="6">通过率：{{ passingRate }} %</el-col>
				<el-col :span="6">完成采集日期：{{ moment(taskInfo.finishTime).format('YYYY年MM月DD日') }}</el-col>
				<el-col :span="6">逾期天数：{{ cutDayCount }} 天</el-col>
				<el-col :span="6">逾期扣款百分比：{{ taskInfo.timeoutCutMoney }} %</el-col>
			</el-row>
			<el-row class="marginBottom-16 row">
				<el-col :span="6" v-if="taskInfo.isSubmit != 1">逾期扣款金额：{{ cutMoney }} 元</el-col>
				<el-col :span="6">结算金额：{{ settlementAmount }} 元</el-col>
			</el-row>
			<el-row class="marginBottom-16 row">
				<el-progress :text-inside="true" :color="customColors" :stroke-width="26" :percentage="passingRate"></el-progress>
			</el-row>
		</template>
		<template v-if="taskInfo.isSubmit == 1">
			<el-alert :closable="false" type="warning" :title="taskInfo.isSubmit == 1 ? '强制提交审核任务' : '超时自动提交审核任务'" description="该任务审核完后不会进行价格结算，并且无法驳回。" />
		</template>
		<el-row class="marginBottom-16 row">
			<el-tabs v-model="tabsActive" @tab-click="handleClick">
				<el-tab-pane v-if="opType == 'review'" label="未审核" name="1"></el-tab-pane>
				<el-tab-pane label="已审核" name="2"></el-tab-pane>
				<template v-if="opType == 'review' && tabsActive == 2 && taskInfo.unPointVos && taskInfo.unPointVos.length == 0">
					<el-row class="typeRadio marginBottom-8">
						<el-col :span="3">提交结果：</el-col>
						<el-col :span="18">
							<el-radio-group class="marginRight-16" v-model="type">
								<el-radio class="marginRight-8" border :label="1">同意</el-radio>
								<el-radio v-if="taskInfo.isSubmit != 1" border :label="2">驳回</el-radio>
							</el-radio-group>
							<el-input
								size="small"
								v-if="type == 2"
								style="width: 50%"
								v-model="days"
								oninput="value=value.replace(/[^0-9]/g,'').replace(/^0{1,}/g,'')"
								autocomplete="off"
								clearable
								placeholder="请输入宽限天数">
								<template slot="append">天</template>
							</el-input>
						</el-col>
						<el-col :span="3">
							<el-button type="primary floatRight" @click="submit">提交</el-button>
						</el-col>
					</el-row>
				</template>
				<el-collapse border v-model="activePointId" @change="handlePointVosCollapseChange" accordion>
					<template v-if="pointDataList && pointDataList.length > 0">
						<el-collapse-item class="pointColItem" v-for="item in pointDataList" :key="item.pointId" :name="item.pointId">
							<template slot="title">
								<div class="titleSpan">
									<div class="dir"></div>
									<div>
										点位名称：{{ item.name || '--' }}
										<el-tag class="marginLeft-8" v-if="item.allIn" type="success" effect="dark" size="mini">该点位中的广告照片已全部审核</el-tag>
									</div>
								</div>
							</template>
							<!-- <el-tag v-if="item.refuseState == 2 && tabsActive == 2" size="mini" type="danger" effect="dark" class="pointFlag">有驳回</el-tag> -->
							<el-button v-if="item.allIn" class="pointSubmitBtn" size="mini" type="primary" @click="itemSubmit(item.pointId)">提交</el-button>
							<el-descriptions border>
								<el-descriptions-item label="具体位置">{{ item.province + item.city + item.district + item.address }}</el-descriptions-item>
							</el-descriptions>
							<el-descriptions border v-if="item.url && item.url != ''">
								<el-descriptions-item labelClassName="pointImgLabel" label="点位照片" :span="3">
									<img class="pointImg" @click="imgPreview(item.url)" :src="imgBaseUrl + item.url" />
								</el-descriptions-item>
							</el-descriptions>
							<el-collapse border v-model="activeMediaId" accordion>
								<template v-if="item.voList && item.voList.length > 0">
									<el-collapse-item v-for="(voItem, index) in item.voList" :key="index" :name="voItem.mediaId">
										<template slot="title">
											<div class="titleSpan">
												<div class="dir"></div>
												<div class="dir"></div>
												<div class="flex">
													媒介名称：{{ voItem.mediaType }}
													<div class="flex alignItemsCenter" v-if="voItem.allIn">
														<el-tag class="marginLeft-8 marginRight-8" type="success" effect="dark" size="mini">该媒介中的广告照片已全部审核</el-tag>
													</div>
												</div>
											</div>
										</template>
										<template v-if="voItem.dataList && voItem.dataList.length != 0">
											<el-descriptions :column="3" v-for="(advertiserItem, advertiserIndex) in voItem.dataList" :title="'广告主-' + (advertiserIndex + 1)" :key="advertiserIndex">
												<el-descriptions-item label="广告主名称">{{ advertiserItem.advertisersName || '--' }}</el-descriptions-item>
												<el-descriptions-item label="手机号">{{ advertiserItem.advertisersPhone || '--' }}</el-descriptions-item>
												<el-descriptions-item label="广告主行业">{{ advertiserItem.industryName || '--' }}</el-descriptions-item>
												<el-descriptions-item label="媒介主名称" :span="3">{{ advertiserItem.mediaOwnerName || '--' }}</el-descriptions-item>
												<el-descriptions-item v-if="tabsActive == 1" label="审核说明" :span="3" content-class-name="reviewRemarkContent">
													<el-input class="advertiserItemmRemark" size="mini" v-model="advertiserItem.reason" placeholder="若广告主信息有误，请在此说明"></el-input>
												</el-descriptions-item>
												<el-descriptions-item v-if="tabsActive == 2 && opType == 'review'" label="审核说明" :span="3" content-class-name="reviewRemarkContent flex">
													<el-input
														class="advertiserItemmRemark2"
														size="mini"
														:disabled="advertiserItem.collectReportState == 1"
														v-model="advertiserItem.remark"
														placeholder="若广告主信息有误，请在此说明；若无误，请清除输入内容后点击确认修改"></el-input>
													<el-button
														class="advertiserItemReasonSubmitBtn"
														v-if="advertiserItem.collectReportState == 0"
														size="mini"
														type="primary"
														@click="editAdvertiserItemReason(advertiserItem)">
														确认修改
													</el-button>
												</el-descriptions-item>
												<el-descriptions-item :span="3" label-class-name="phoneLabel" content-class-name="phoneContent" label="广告照片">
													<template v-if="tabsActive == 1">
														<el-row class="imgRowBox">
															<template v-for="imgItem in advertiserItem.vos">
																<div class="imgBox" :key="imgItem.flowId">
																	<img @click="imgPreview(imgItem.url)" :src="imgBaseUrl + imgItem.url" />
																	<el-tag
																		class="detailImgTag"
																		size="mini"
																		v-if="imgItem.detailImg && imgItem.detailImg != ''"
																		effect="dark"
																		@click="detailImgPreview(imgItem.detailImg)">
																		细节图片
																	</el-tag>
																	<el-checkbox
																		border
																		v-if="!imgItem.checkedResult && opType == 'review' && imgItem.reviewResults == 3"
																		class="checkBox"
																		v-model="imgItem.checked"
																		@change="taskInfoUpdata"></el-checkbox>
																	<i v-show="imgItem.checkedResult == 1 || imgItem.reviewResults == 1" class="el-icon-success successIcon"></i>
																	<el-tooltip placement="top" :content="imgItem.reason">
																		<i v-show="imgItem.checkedResult == 2 || imgItem.reviewResults == 2" class="el-icon-warning warnIcon"></i>
																	</el-tooltip>
																	<el-tooltip placement="top" content="疑似重复，点击查看详情">
																		<i
																			@click="repeatDataInfo(imgItem)"
																			v-show="imgItem.repeatData && imgItem.repeatData.length != 0 && imgItem.checkedResult != 1 && imgItem.reviewResults != 1"
																			class="el-icon-warning warnIcon warnIcon1"></i>
																	</el-tooltip>
																	<div class="imgItemReason" v-if="!imgItem.checkedResult && imgItem.reviewResults == 3">未审核</div>
																	<div class="imgItemReason-1" v-if="imgItem.checkedResult == 1 || imgItem.reviewResults == 1">
																		通过
																		<el-button v-if="imgItem.reviewResults == 3" class="marginLeft-16" type="text" size="mini" @click="delReason(imgItem.flowId)">
																			修改
																		</el-button>
																	</div>
																	<div class="imgItemReason-2" v-if="imgItem.checkedResult == 2 || imgItem.reviewResults == 2">
																		拒绝
																		<el-button v-if="imgItem.reviewResults == 3" class="marginLeft-16" type="text" size="mini" @click="delReason(imgItem.flowId)">
																			修改
																		</el-button>
																	</div>
																</div>
															</template>
														</el-row>
													</template>
													<template v-if="tabsActive == 2">
														<el-row class="imgRowBox">
															<template v-for="imgItem in advertiserItem.vos">
																<div class="imgBox" :key="imgItem.flowId" v-if="imgItem.reviewResults != 3">
																	<img @click="imgPreview(imgItem.url)" :src="imgBaseUrl + imgItem.url" />
																	<i v-show="imgItem.reviewResults == 1" class="el-icon-success successIcon"></i>
																	<el-tooltip placement="top" :content="imgItem.reviewComments">
																		<i v-show="imgItem.reviewResults == 2" class="el-icon-warning warnIcon"></i>
																	</el-tooltip>
																	<template v-if="opType == 'review'">
																		<div class="imgItemReason-1" v-if="imgItem.reviewResults == 1">
																			通过
																			<el-button v-if="imgItem.collectReportState == 0" class="marginLeft-16" type="text" size="mini" @click="edit(imgItem)">
																				修改
																			</el-button>
																		</div>
																		<div class="imgItemReason-2" v-if="imgItem.reviewResults == 2">
																			拒绝
																			<el-button v-if="imgItem.collectReportState == 0" class="marginLeft-16" type="text" size="mini" @click="edit(imgItem)">
																				修改
																			</el-button>
																		</div>
																	</template>
																</div>
															</template>
														</el-row>
													</template>
												</el-descriptions-item>
											</el-descriptions>
											<template v-if="tabsActive == 1 && opType == 'review' && voItem.checkedFlag">
												<el-row class="confirmBox marginBottom-8">
													<template v-if="refuseItem">
														<div class="floatRight">
															<el-button size="mini" type="primary" @click="regionConfirm(2)">确定</el-button>
														</div>
														<el-input
															v-model="reasonNew"
															size="mini"
															style="width: 30%"
															class="floatRight"
															v-if="reason == '自定义原因'"
															placeholder="请输入自定义原因"></el-input>
														<div class="floatRight">
															<el-select size="mini" v-model="reason" placeholder="请选择原因">
																<el-option v-for="(item, index) in reasonArr" :key="index" :label="item" :value="item"></el-option>
																<el-option label="自定义原因" value="自定义原因"></el-option>
															</el-select>
														</div>
													</template>
													<div class="floatRight marginRight-8">
														<el-button size="mini" type="danger" @click="refuseItem = true">拒绝</el-button>
													</div>
													<div class="floatRight">
														<el-button size="mini" type="primary" @click="regionConfirm(1)">通过</el-button>
													</div>
												</el-row>
											</template>
										</template>
									</el-collapse-item>
								</template>
							</el-collapse>
						</el-collapse-item>
					</template>
				</el-collapse>
			</el-tabs>
		</el-row>
		<Vviewer ref="viewer"></Vviewer>
		<user-info :visible="drawerVisible" :userCode="queryUserCode" @closeDrawer="closeDrawer" />
		<el-dialog append-to-body title="更新" :visible.sync="updataDialogVisible" width="30%">
			<div v-if="updataImgInfo.reviewResults == 1" class="marginBottom-8">修改结果：拒绝</div>
			<div v-if="updataImgInfo.reviewResults == 2" class="marginBottom-8">
				修改结果：
				<el-radio-group size="mini" v-model="updataReviewResults">
					<el-radio-button label="1">通过</el-radio-button>
					<el-radio-button label="2">拒绝</el-radio-button>
				</el-radio-group>
			</div>
			<div v-if="updataImgInfo.reviewResults == 1 || updataReviewResults == 2">
				修改原因：
				<el-input style="width: 70%" placeholder="请输入拒绝原因" v-model="updataReviewComments"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="updataDialogClose">取 消</el-button>
				<el-button type="primary" @click="updataDialogConfirm">确 定</el-button>
			</span>
		</el-dialog>
		<VideoPreview :dialogVisible="VideoPreviewShow" :videoUrl="videoUrl" @dialogClosed="VideoPreviewClose" />
		<RepeatDataReview :visible="repeatDataReviewVisible" :repeatData="repeatData" @repeatDataReviewClose="repeatDataReviewClose" />
	</el-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { queryTaskReviewInfo, queryDatapointCollectionInfo, examineTask, reviewData, datacollectionUpdate, dataadvertisersUpdate } from '@/api/task';
import Vviewer from '@/components/Vviewer';
import VideoPreview from '@/components/VideoPreview';
import UserInfo from '@/components/UserInfo';
import RepeatDataReview from './RepeatDataReview.vue';
import { debounce } from '@/utils';
export default {
	props: ['taskReviewVisible', 'op', 'opType'],
	components: { Vviewer, UserInfo, VideoPreview, RepeatDataReview },
	data() {
		return {
			VideoPreviewShow: false,
			videoUrl: '',
			updataReviewComments: '',
			updataReviewResults: '2',
			updataImgInfo: {},
			updataDialogVisible: false,
			pointDataList: [],
			refuseItem: false,
			drawerVisible: false,
			queryUserCode: null,
			tabsActive: '1',
			days: '',
			type: 1,
			reasonNew: '',
			reason: '',
			reasonArr: ['图片模糊', '图片光线太暗', '图片有遮挡物', '图片反光严重'],
			activeMediaId: '',
			activePointId: '',
			customColors: [
				{ color: '#f5222d', percentage: 20 },
				{ color: '#e6a23c', percentage: 40 },
				{ color: '#6f7ad3', percentage: 60 },
				{ color: '#1989fa', percentage: 80 },
				{ color: '#5cb87a', percentage: 100 },
			],
			failCount: 0,
			passCount: 0,
			settlementAmount: 0,
			passingRate: 0,
			taskInfo: {},
			moment,
			cutDayCount: 0, // 逾期天数
			cutMoney: 0, // 逾期扣除金额
			repeatDataReviewVisible: false,
			repeatData: null,
		};
	},
	watch: {
		taskReviewVisible(data) {
			const _this = this;
			if (data) {
				if (this.opType == 'query') {
					this.tabsActive = '2';
				}
				_this.getTaskfo();
			}
		},
	},
	computed: {
		...mapGetters(['userInfo']),
		imgBaseUrl() {
			return process.env.VUE_APP_RESOURCES_HOST;
		},
	},
	methods: {
		repeatDataInfo(data) {
			this.repeatDataReviewVisible = true;
			this.repeatData = data;
		},
		repeatDataReviewClose(type) {
			this.repeatDataReviewVisible = false;
			if (type == 1 || type == 2) {
				let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
				let activePointId = this.activePointId;
				let activeMediaId = this.activeMediaId;
				pointDataList.map(pointItem => {
					if (activePointId == pointItem.pointId) {
						pointItem.allIn = true;
						pointItem.voList.map((voItem, voIndex) => {
							if (voItem.mediaId == activeMediaId) {
								voItem.allIn = true;
								voItem.dataList.map(advertiserItem => {
									advertiserItem.vos.map(imgItem => {
										if (imgItem.flowId == this.repeatData.flowId && type == 2 && (!imgItem.checkedResult || imgItem.checkedResult == 3)) {
											imgItem.checkedResult = 2;
											imgItem.reason = '重复照片';
										} else if (imgItem.flowId == this.repeatData.flowId && type == 1 && (!imgItem.checkedResult || imgItem.checkedResult == 3)) {
											imgItem.checkedResult = 1;
											imgItem.reason = '通过';
										}
										if (!imgItem.checkedResult) {
											voItem.allIn = false;
										}
									});
								});
								voItem.checkedFlag = false;
							}
							if (!voItem.allIn) {
								pointItem.allIn = false;
							}
						});
					}
				});
				this.pointDataList = pointDataList;
				this.taskInfo.unPointVos = pointDataList;
			}
			this.repeatData = null;
		},
		VideoPreviewClose() {
			this.videoUrl = '';
			this.VideoPreviewShow = false;
		},
		updataDialogConfirm() {
			let params = {
				flowId: this.updataImgInfo.flowId,
				reviewResults: this.updataReviewResults,
			};
			if (this.updataReviewResults == 2) {
				if (this.updataReviewComments == '') {
					this.$message({
						message: '请输入拒绝原因！',
						type: 'warning',
					});
					return;
				}
				params.reviewComments = this.updataReviewComments;
			}
			const _this = this;
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			datacollectionUpdate(params)
				.then(res => {
					let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
					let activePointId = this.activePointId;
					let activeMediaId = this.activeMediaId;
					pointDataList.map(pointItem => {
						if (activePointId == pointItem.pointId) {
							pointItem.voList.map((voItem, voIndex) => {
								if (voItem.mediaId == activeMediaId) {
									voItem.dataList.map(advertiserItem => {
										advertiserItem.vos.map(imgItem => {
											if (imgItem.flowId == params.flowId) {
												imgItem.reviewResults = params.reviewResults;
												imgItem.reviewComments = '';
												if (params.reviewResults == 2) {
													imgItem.reviewComments = params.reviewComments;
												}
											}
										});
									});
									voItem.checkedFlag = false;
								}
								if (voItem.allIn == false) {
									pointItem.allIn = false;
								}
							});
						}
					});
					_this.pointDataList = pointDataList;
					loading.close();
					_this.taskInfo.pointVos = pointDataList;
					let taskInfo = JSON.parse(JSON.stringify(_this.taskInfo));
					if (params.reviewResults == 1) {
						_this.failCount = _this.failCount - 1 <= 0 ? 0 : _this.failCount - 1;
						_this.passCount = _this.passCount + 1;
					} else if (params.reviewResults == 2) {
						_this.failCount = _this.failCount + 1;
						_this.passCount = _this.passCount - 1 <= 0 ? 0 : _this.passCount - 1;
					}
					_this.passingRate =
						Math.round(Math.floor((_this.passCount / _this.taskInfo.totalItemCount) * 1000) / 10) >= 100
							? 100
							: Math.round(Math.floor((_this.passCount / _this.taskInfo.totalItemCount) * 1000) / 10);
					if (_this.passCount > taskInfo.totalItemCount) {
						_this.settlementAmount = (taskInfo.totalItemCount * taskInfo.itemPrice) / 1000;
					} else {
						_this.settlementAmount = (_this.passCount * taskInfo.itemPrice) / 1000;
					}
					// 如果实际完成时间大于任务所需完成的时间则逾期
					if (moment(finishTime).isAfter(moment(taskInfo.receiveTime).add(taskInfo.taskDays, 'days'))) {
						// 计算逾期天数
						let cutDayCount = moment(finishTime).diff(moment(taskInfo.receiveTime).add(taskInfo.taskDays, 'days'), 'days');
						let cutMoney = Math.round(Math.floor(_this.settlementAmount * (taskInfo.timeoutCutMoney / 100) * cutDayCount * 1000) / 10) / 100;
						_this.cutDayCount = cutDayCount;
						_this.cutMoney = cutMoney;
						_this.settlementAmount = _this.settlementAmount - cutMoney <= 0 ? 0 : _this.settlementAmount - cutMoney;
					}
					_this.$message({
						message: '更新成功',
						type: 'success',
					});
					_this.updataDialogClose();
				})
				.catch(err => {
					loading.close();
					console.log(err);
				});
		},
		updataDialogClose() {
			this.updataImgInfo = {};
			this.updataDialogVisible = false;
			this.updataReviewResults = '2';
		},
		editAdvertiserItemReason(data) {
			let params = {
				remark: data.remark,
				reviewResults: data.remark && data.remark != '' ? 2 : 1,
				flowId: data.advertisersId,
			};
			const _this = this;
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			dataadvertisersUpdate(params)
				.then(res => {
					loading.close();
				})
				.catch(err => {
					console.log(err);
					loading.close();
				});
		},
		edit(data) {
			this.updataImgInfo = data;
			this.updataDialogVisible = true;
			if (data.reviewResults == 2) {
				this.updataReviewComments = data.reviewComments;
			} else if (data.reviewResults == 1) {
				this.updataReviewComments = '';
			}
		},
		taskInfoUpdata() {
			let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
			pointDataList.map(pointItem => {
				if (pointItem.voList && pointItem.voList.length != 0) {
					pointItem.voList.map((voItem, voIndex) => {
						voItem.checkedFlag = false;
						this.refuseItem = false;
						voItem.dataList.map(advertiserItem => {
							advertiserItem.vos.map(imgItem => {
								if (imgItem.checked && !imgItem.checkedResult) {
									voItem.checkedFlag = true;
								}
							});
						});
					});
				}
			});
			this.pointDataList = pointDataList;
		},
		closeDrawer() {
			this.drawerVisible = false;
			this.queryUserCode = null;
		},
		queryUserInfo(userCode) {
			this.queryUserCode = userCode;
			this.drawerVisible = true;
		},
		itemSubmit: debounce(function (pointId) {
			const _this = this;
			let params = {
				passIds: [],
				rejectIds: [],
			};
			let passIds = [];
			let rejectIds = [];
			let passAdIds = [];
			let rejectAdIds = [];
			let activeMediaId = '';
			let activePointId = '';
			let flag = true;
			let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
			pointDataList.map(pointItem => {
				if (pointItem.voList && pointItem.voList.length != 0) {
					if (pointItem.pointId == pointId) {
						pointItem.voList.map((voItem, voIndex) => {
							voItem.dataList.map(advertiserItem => {
								advertiserItem.vos.map(imgItem => {
									if (imgItem.reviewResults == 3) {
										if (imgItem.checkedResult == 2 && (imgItem.reason == '' || !imgItem.reason)) {
											flag = false;
											activeMediaId = voItem.mediaId;
											activePointId = pointItem.pointId;
										}
										if (imgItem.checkedResult == 2) {
											let obj = {
												flowId: imgItem.flowId,
												reason: imgItem.reason,
											};
											rejectIds.push(obj);
										} else if (imgItem.checkedResult == 1) {
											passIds.push(imgItem.flowId);
										}
									}
								});
								if (advertiserItem.reason && advertiserItem.reason != '') {
									let obj = {
										flowId: advertiserItem.advertisersId,
										reason: advertiserItem.reason,
									};
									rejectAdIds.push(obj);
								} else {
									passAdIds.push(advertiserItem.advertisersId);
								}
							});
						});
					}
				}
			});
			if (!flag) {
				this.$message({
					message: '请选择不通过的原因',
					type: 'warning',
				});
				_this.activeMediaId = activeMediaId;
				_this.activePointId = activePointId;
				return;
			}
			params.passIds = passIds;
			params.rejectIds = rejectIds;
			params.passAdIds = passAdIds;
			params.rejectAdIds = rejectAdIds;
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			reviewData(params)
				.then(res => {
					loading.close();
					_this.getTaskfo();
				})
				.catch(err => {
					loading.close();
					console.log(err);
				});
		}, 300),
		handleClick() {
			let voList = [];
			let taskInfo = this.taskInfo;
			let pointDataList = [];
			if (this.tabsActive == 1) {
				pointDataList = taskInfo.unPointVos;
			} else if (this.tabsActive == 2) {
				pointDataList = taskInfo.pointVos;
			}
			if (pointDataList.length != 0) {
				this.activePointId = pointDataList[0].pointId;
				voList = pointDataList[0].voList;
				if (!voList || voList.length == 0) {
					this.getVoList(pointDataList[0].pointId);
				} else {
					this.pointDataList = pointDataList;
					this.activeMediaId = voList[0].mediaId;
				}
			} else {
				this.pointDataList = [];
				this.activePointId = '';
				this.activeMediaId = '';
			}
		},
		delReason(flowId) {
			let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
			let activePointId = this.activePointId;
			let activeMediaId = this.activeMediaId;
			pointDataList.map(pointItem => {
				if (activePointId == pointItem.pointId) {
					pointItem.voList.map((voItem, voIndex) => {
						if (voItem.mediaId == activeMediaId) {
							voItem.dataList.map(advertiserItem => {
								advertiserItem.vos.map(imgItem => {
									if (imgItem.flowId == flowId) {
										delete imgItem.reason;
										delete imgItem.checkedResult;
										imgItem.checked = false;
									}
								});
							});
						}
					});
				}
			});
			this.pointDataList = pointDataList;
			this.updataList();
		},
		submit: debounce(function () {
			let params = {
				taskId: this.op,
				type: this.type,
			};
			if (this.type == 2) {
				if (this.days == '' || !this.days) {
					this.$message({
						message: '请选择宽限天数',
						type: 'warning',
					});
					return;
				}
				params.days = this.days;
			}
			let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
			let flag = false;
			pointDataList.map(pointItem => {
				if (pointItem.voList && pointItem.voList.length != 0) {
					pointItem.voList.map((voItem, voIndex) => {
						voItem.dataList.map(advertiserItem => {
							if (advertiserItem.remark && advertiserItem.remark != '') {
								flag = true;
							}
							advertiserItem.vos.map(imgItem => {
								if (imgItem.reviewResults == 2) {
									flag = true;
								}
							});
						});
					});
				}
			});
			if (this.type == 2) {
				if (!flag) {
					this.$message({
						message: '请拒绝图片或填写广告主拒绝说明',
						type: 'warning',
					});
					return;
				}
			}
			const _this = this;
			const loading = _this.$loading({
				lock: true,
				text: '提交中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			examineTask(params)
				.then(() => {
					loading.close();
					_this.closeDialog(true);
				})
				.catch(err => {
					console.log(err);
					loading.close();
				});
		}, 300),
		computedCount() {
			let taskInfo = JSON.parse(JSON.stringify(this.taskInfo));
			let failCount = 0;
			let passCount = 0;
			let totalCount = taskInfo.totalItemCount;
			taskInfo.pointVos.map(pointItem => {
				pointItem.voList.map((voItem, voIndex) => {
					voItem.dataList.map(advertiserItem => {
						advertiserItem.vos.map(imgItem => {
							if (imgItem.checked && imgItem.reason) {
								failCount += 1;
							}
						});
					});
				});
			});
			passCount = totalCount - failCount;
			if (passCount <= 0) {
				passCount = 0;
			}
			this.passingRate = Math.round(Math.floor((passCount / totalCount) * 1000) / 10) >= 100 ? 100 : Math.round(Math.floor((passCount / totalCount) * 1000) / 10);
			this.failCount = failCount;
			this.passCount = passCount;
			if (passCount > taskInfo.totalItemCount) {
				this.settlementAmount = (taskInfo.totalItemCount * taskInfo.itemPrice) / 1000;
			} else {
				this.settlementAmount = (passCount * taskInfo.itemPrice) / 1000;
			}
			// 如果实际完成时间大于任务所需完成的时间则逾期
			if (moment(finishTime).isAfter(moment(taskInfo.receiveTime).add(taskInfo.taskDays, 'days'))) {
				// 计算逾期天数
				let cutDayCount = moment(finishTime).diff(moment(taskInfo.receiveTime).add(taskInfo.taskDays, 'days'), 'days');
				let cutMoney = Math.round(Math.floor(_this.settlementAmount * (taskInfo.timeoutCutMoney / 100) * cutDayCount * 1000) / 10) / 100;
				_this.cutDayCount = cutDayCount;
				_this.cutMoney = cutMoney;
				_this.settlementAmount = _this.settlementAmount - cutMoney <= 0 ? 0 : _this.settlementAmount - cutMoney;
			}
		},
		updataList(type) {
			let pointDataList = JSON.parse(JSON.stringify(this.pointDataList));
			let activePointId = this.activePointId;
			let activeMediaId = this.activeMediaId;
			pointDataList.map(pointItem => {
				if (activePointId == pointItem.pointId) {
					pointItem.allIn = true;
					pointItem.voList.map((voItem, voIndex) => {
						if (voItem.mediaId == activeMediaId) {
							voItem.allIn = true;
							voItem.dataList.map(advertiserItem => {
								advertiserItem.vos.map(imgItem => {
									if (imgItem.checked && type == 2 && (!imgItem.checkedResult || imgItem.checkedResult == 3)) {
										imgItem.checkedResult = 2;
										if (this.reason == '自定义原因') {
											imgItem.reason = this.reasonNew;
										} else {
											imgItem.reason = this.reason;
										}
									} else if (imgItem.checked && type == 1 && (!imgItem.checkedResult || imgItem.checkedResult == 3)) {
										imgItem.checkedResult = 1;
										imgItem.reason = '通过';
									}
									if (!imgItem.checkedResult) {
										voItem.allIn = false;
									}
								});
							});
							voItem.checkedFlag = false;
						}
						if (!voItem.allIn) {
							pointItem.allIn = false;
						}
					});
				}
			});
			this.pointDataList = pointDataList;
			this.taskInfo.unPointVos = pointDataList;
			this.reasonNew = '';
			this.reason = '';
			this.refuseItem = false;
			this.repeatData = null;
		},
		regionConfirm(type) {
			if (type == 2) {
				if (this.reason == '') {
					this.$message({
						message: '请选择原因',
						type: 'warning',
					});
					return;
				}
				if (this.reason == '自定义原因') {
					let flag = false;
					let reasonArr = this.reasonArr;
					if (this.reasonNew == '') {
						this.$message({
							message: '输入自定义原因',
							type: 'warning',
						});
						return;
					}
					reasonArr.map(item => {
						if (item == this.reasonNew) {
							flag = true;
						}
					});
					// flag = true表示该自定义原因与枚举值列表中的某一项重复,如果为false则枚举值列表中没有此自定义原因，则往枚举值列表添加
					if (!flag) {
						reasonArr.splice(0, 0, this.reasonNew);
					}
				}
			}
			// 将原因更新到图片信息中
			this.updataList(type);
		},
		detailImgPreview(urlStr) {
			const _this = this;
			let list = urlStr.split(',');
			let imgList = [];

			list.map(item => {
				let obj = {
					thumbnail: _this.imgBaseUrl + item,
					source: _this.imgBaseUrl + item,
				};
				imgList.push(obj);
			});
			this.$refs.viewer.show(imgList);
		},
		imgPreview(url) {
			const _this = this;
			let type = url.split('.')[1];
			if (type == 'mp4') {
				this.videoUrl = this.imgBaseUrl + url;
				this.VideoPreviewShow = true;
			} else {
				this.$refs.viewer.show([
					{
						thumbnail: _this.imgBaseUrl + url,
						source: _this.imgBaseUrl + url,
					},
				]);
			}
		},
		getVoList(activePointId) {
			let params = {
				pointId: activePointId,
				taskId: this.taskInfo.taskId,
			};
			const _this = this;
			queryDatapointCollectionInfo(params)
				.then(res => {
					let taskInfo = _this.taskInfo;
					let pointDataList = [];
					if (_this.tabsActive == 1) {
						pointDataList = taskInfo.unPointVos;
					} else if (_this.tabsActive == 2) {
						pointDataList = taskInfo.pointVos;
					}
					pointDataList.map(item => {
						if (item.pointId == res.data.pointId) {
							item.voList = res.data.voList;
							_this.activeMediaId = res.data.voList[0].mediaId;
							item.voList.map(voItem => {
								voItem.allIn = true;
								voItem.dataList.map(colleItem => {
									colleItem.vos.map(imgItem => {
										if (imgItem.reviewResults == 1 || imgItem.reviewResults == 2) {
											imgItem.checked = true;
											imgItem.checkedResult = imgItem.reviewResults;
										}
										if (imgItem.reason) {
											imgItem.checked = true;
										}
										if (!imgItem.checkedResult) {
											voItem.allIn = false;
										}
									});
								});
							});
						}
					});
					_this.pointDataList = pointDataList;
				})
				.catch(err => {
					console.log(err);
				});
		},
		handlePointVosCollapseChange() {
			if (!this.activePointId || this.activePointId == '') {
				return;
			}
			let voList = [];
			let taskInfo = this.taskInfo;
			let pointDataList = [];
			if (this.tabsActive == 1) {
				pointDataList = taskInfo.unPointVos;
			} else if (this.tabsActive == 2) {
				pointDataList = taskInfo.pointVos;
			}
			pointDataList.map(item => {
				if (item.pointId == this.activePointId) {
					voList = item.voList;
				}
			});
			if (!voList || voList.length == 0) {
				this.getVoList(this.activePointId);
			} else {
				this.pointDataList = pointDataList;
				this.activeMediaId = voList[0].mediaId;
			}
		},
		getTaskfo() {
			const _this = this;
			let params = {
				taskId: this.op,
			};
			const loading = _this.$loading({
				lock: true,
				text: '加载中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'fullScreenLoading',
			});
			queryTaskReviewInfo(params)
				.then(res => {
					_this.taskInfo = res.data;
					_this.failCount = res.data.failCount;
					_this.passCount = res.data.passCount;
					_this.passingRate =
						Math.round(Math.floor((_this.passCount / res.data.totalItemCount) * 1000) / 10) >= 100 ? 100 : Math.round(Math.floor((_this.passCount / res.data.totalItemCount) * 1000) / 10);
					if (_this.passCount > res.data.totalItemCount) {
						_this.settlementAmount = (res.data.totalItemCount * res.data.itemPrice) / 1000;
					} else {
						_this.settlementAmount = (res.data.passCount * res.data.itemPrice) / 1000;
					}
					let finishTime = res.data.finishTime;
					// 如果实际完成时间大于任务所需完成的时间则逾期
					if (moment(finishTime).isAfter(moment(res.data.receiveTime).add(res.data.taskDays, 'days'))) {
						// 计算逾期天数
						let cutDayCount = moment(finishTime).diff(moment(res.data.receiveTime).add(res.data.taskDays, 'days'), 'days');
						let cutMoney = Math.round(Math.floor(_this.settlementAmount * (res.data.timeoutCutMoney / 100) * cutDayCount * 1000) / 10) / 100;
						_this.cutDayCount = cutDayCount;
						_this.cutMoney = cutMoney;
						_this.settlementAmount = _this.settlementAmount - cutMoney <= 0 ? 0 : Math.round(Math.floor((_this.settlementAmount - cutMoney) * 1000) / 10) / 100;
					}
					loading.close();
					let activePointId = '';
					if (_this.tabsActive == 1) {
						if (res.data.unPointVos.length != 0) {
							activePointId = res.data.unPointVos[0].pointId;
						}
					} else if (_this.tabsActive == 2) {
						if (res.data.pointVos.length != 0) {
							activePointId = res.data.pointVos[0].pointId;
						}
					}
					_this.activePointId = activePointId;
					if (_this.activePointId && _this.activePointId != '') {
						_this.getVoList(activePointId);
						_this.queryListByFile();
					} else {
						_this.pointDataList = [];
					}
				})
				.catch(() => {
					loading.close();
				});
		},
		closeDialog(flag) {
			this.$emit('onTaskReviewClose', flag);
			this.days = '';
			this.type = 1;
			this.reasonNew = '';
			this.reason = '';
			this.activeMediaId = '';
			this.activePointId = '';
			this.tabsActive = '1';
			this.failCount = 0;
			this.passCount = 0;
			this.settlementAmount = 0;
			this.passingRate = 0;
			this.taskInfo = {};
			this.pointDataList = [];
			this.refuseItem = false;
			this.drawerVisible = false;
			this.queryUserCode = null;
			this.cutDayCount = 0;
			this.cutMoney = 0;
		},
	},
};
</script>

<style lang="less" scoped>
/deep/ .reviewRemarkContent {
	width: 85%;
	.advertiserItemmRemark {
		width: 100%;
	}
	.advertiserItemmRemark2 {
		width: 86%;
	}
	.advertiserItemReasonSubmitBtn {
		width: 14%;
	}
}

/deep/ .pointImgLabel {
	width: 150px;
}
.pointImg {
	width: 100px;
	height: 80px;
	cursor: pointer;
}
.pointColItem {
	position: relative;
	.pointSubmitBtn {
		position: absolute;
		right: 28px;
		top: 8px;
	}
	.pointFlag {
		position: absolute;
		right: 28px;
		top: 12px;
	}
}
.titleSpan {
	font-size: 16px;
	font-weight: 700;
	display: flex;
	align-items: center;
	& > div {
		display: inline-block;
	}
	div.dir {
		width: 0;
		height: 20px;
		border: 2px solid #66b1ff;
		margin-right: 8px;
	}
}
/deep/ .el-collapse-item__content {
	padding-bottom: 0;
}
.typeRadio {
	display: flex;
	align-items: center;
}
.warnText {
	color: #ff4d4f;
}
.confirmBox {
	// display: flex;
	div {
		display: inline-block;
	}
}
/deep/ .phoneLabel {
	width: 10%;
}
/deep/ .phoneContent {
	width: 90%;
}
.imgRowBox {
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	.imgBox {
		width: 150px;
		height: 150px;
		margin-right: 8px;
		position: relative;
		display: inline-block;
		.imgItemReason,
		.imgItemReason-1,
		.imgItemReason-2 {
			font-size: 12px;
			width: 100%;
			.el-button {
				padding: 0;
			}
		}
		.imgItemReason-1 {
			color: #52c41a;
		}
		.imgItemReason-2 {
			color: #f5222d;
		}
		.warnIcon {
			position: absolute;
			right: 0;
			top: 0;
			color: #f5222d;
			font-size: 28px;
			cursor: pointer;
		}
		.warnIcon1 {
			color: #f2ce07;
			right: 32px;
		}
		.successIcon {
			position: absolute;
			right: 0;
			top: 0;
			color: #52c41a;
			font-size: 28px;
			cursor: pointer;
		}
		.detailImgTag {
			position: absolute;
			right: 4px;
			top: 4px;
			cursor: pointer;
		}
		.checkBox {
			position: absolute;
			left: 0;
			top: 0;
			border: 2px solid #ccc;
			padding: 4px;
			height: auto;
		}
		/deep/ .checkBox.is-checked {
			border-color: #409eff;
		}
		img {
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}
}

.row {
	padding: 0 32px;
	.el-col {
		font-size: 16px;
	}
}
</style>
